<template>
  <div class="card">
    <div class="card-header bg-navyblue">
      <strong>Recoveries</strong>
    </div>
    <div class="card-block bg-white">
      <div class="form-group row">
        <label class="col-sm-4 col-4 col-form-label">Date With Recoveries</label>
        <div class="col-sm-8 col-8">
          <b-form-datepicker
              v-model="recoveries.dateWithRecoveries"
              class="mb-2 "
              locale="en-AU"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
              :disabled="isAllDisable"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-4 col-form-label">Date Settled</label>
        <div class="col-sm-8 col-8">
          <b-form-datepicker
              v-model="recoveries.dateSettled"
              class="mb-2 "
              locale="en-AU"
              :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
              v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
              :disabled="isAllDisable"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label for="legalRef" class="col-sm-4 col-4 col-form-label">Legal Ref</label>
        <div class="col-sm-8 col-8">
          <input type="text" id="legalRef" v-model="recoveries.legalRef" class="form-control" placeholder="" :disabled="isAllDisable">
        </div>
      </div>
      <div class="form-group row">
        <label for="legalRef" class="col-sm-4 col-4 col-form-label">Legal Firm</label>
        <div class="col-sm-8 col-8">
          <advanced-search ref="rental.legalFirm"
                           searchLabel="Legal Firm"
                           v-model="computedLegalFirm"
                           :searchData="legalFirms"
                           searchKey="name"
                           @onSelect="onLegalFirmSelect"
                           @onUpdate="onLegalFirmUpdate"
                           :placeholderText="'Legal Firm'"
                           :is-disabled="isAllDisable"

          >
          </advanced-search>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdvancedSearch from "@/components/utility/advanced-search";
import _ from "lodash";

export default {
  name: "block-rental-recoveries",
  components: {
    AdvancedSearch,
  },
  props: {
    recoveries: {
      type: [Object, null],
      default(){
        return {
          dateWithRecoveries: '',
          dateSettled: '',
          legalFirm: null,
          legalRef: '',
        };
      }
    },
    legalFirms: {
      type: [Array, null],
      default() {
        return [];
      }
    },
    isAllDisable: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data(){
    return {
      legalFirmName: '',
    };
  },
  computed:{
    computedLegalFirm: {
      get() {
        return this.legalFirmName;
      },
      set(val) {
        this.legalFirmName = val;
      },
    },
  },
  mounted(){
    this.$nextTick(() => {
      this.changeLegalFirm(this.recoveries.legalFirm);
    });
  },
  methods:{
    onLegalFirmSelect(val) {
      if (val) {
        this.recoveries.legalFirm = val.id;
        this.legalFirmName = val.name;
      } else {
        this.$emit('new-legal-firm', this.legalFirmName);
      }
      console.log('onLegalFirmSelect', val);
    },
    onLegalFirmUpdate(){},
    changeLegalFirm(val) {
      let lf = _.find(this.legalFirms, item => {
        return Number(item.id) === Number(val);
      });
      if (lf && lf.name) {
        this.legalFirmName = lf.name;
      } else {
        this.legalFirmName = '';
      }
    },
  },
  watch: {
    'recoveries.legalFirm': {
      deep: true,
      handler: function (newVal) {
        this.changeLegalFirm(newVal);
      }
    },
  },
}
</script>

<style scoped>

</style>
