<script>
    /*eslint-disable */
    import Axios from 'axios';
    import {mapGetters} from 'vuex';
    //import {VStepper} from 'vue-stepper-component';
    import NumberFormatter from '@/components/number-formatter';
    import GoodWizard from '@/components/vue-good-wizard';
    import Multiselect from 'vue-multiselect';
    import _ from "lodash";

    export default {
        name: 'claim-new',
        data: function () {
            return {
                isCreatingClaim: false,
                steps: [
                    {
                        label: 'Vehicle Owner',
                        slot: 'claim-new-step-1',
                    },
                    {
                        label: 'Accident',
                        slot: 'claim-new-step-2',
                        options: {
                            nextDisabled: true // control whether next is disabled or not
                        },
                    },
                    {
                        label: 'Vehicle Information',
                        slot: 'claim-new-step-3'
                    }
                ],
                step: 1,
                claim: {
                    owner: {
                        driverName: '',
                        name: '',
                        ownerType: 'I',
                        abn: '',
                        contact: '',
                        fax: '',
                        email: '',
                        levelUnitLot: '',
                        streetName: '',
                        suburb: '',
                        state: '',
                        postcode: '',
                        country: '',
                    },
                    accident: {
                        otherDriverName: '',
                        otherDriverContactNbr: '',
                        otherDriverLicenseNbr: '',
                        otherDriverInsurer: '',
                        fault: null,
                        date: '',
                        levelUnitLot: '',
                        streetName: '',
                        suburb: '',
                        state: '',
                        postcode: '',
                        country: '',
                        description: '',
                        witnessName: '',
                        witnessEmail: '',
                        isPoliceReport: null,
                        isTowing: null,
                    },
                    vehicle: {
                        rego: '',
                        dom: '',
                        make: '',
                        model: '',
                        series: '',
                        scheduleNumber: '',
                        colour: '',
                        colourVariant: '',
                        badge: '',
                        body: '',
                        vin: '',
                        odometer: 0,
                        transmission: '',
                    },
                    innerRepairerId: null,
                },
                repairers: [],
            };
        },
        methods: {
            loadRepairers() {
                NProgress.start();
                Axios.get('/ir/repairers')
                    .then(response => {
                        if (response && response.data && response.data._status) {
                            this.repairers = response.data.data;
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                        NProgress.done();
                    });
            },
            clickCancel: function () {
                this.$router.push({name: 'Claims'});
            },
            clickCreateClaim: function () {
                NProgress.start();
                this.isCreatingClaim = true;
                let data = {
                    claim: this.claim,
                };
                Axios.post(`/ir/claim/new`, data)
                    .then(response => {
                        if (response.data._status && response.data.id > 0) {
                            this.isCreatingClaim = false;
                            this.$router.push({name: 'ClaimView', params: {claimId: response.data.id}});
                            return;
                        }
                    })
                    .catch(err => {
                        this.$toast.error(err);
                    })
                    .finally(e => {
                        NProgress.done();
                        this.isCreatingClaim = false;
                    });
            },
        },
        computed: {
            ...mapGetters([
                'addressStates',
                'addressCountries',
            ]),
            addressCountry: {
                get() {
                    return _.filter(this.addressCountries, item => {
                        return item.key === this.claim.owner.country;
                    });
                },
                set(value) {
                    this.claim.owner.country = value.key;
                }
            },
            addressState: {
                get() {
                    return _.filter(this.addressStates, item => {
                        return item.key === this.claim.owner.state;
                    });
                },
                set(value) {
                    this.claim.owner.state = value.key;
                }
            }
        },
        mounted: function () {
            if (!this.isInsurance) {
                //return this.$router.push('/claims'); //ToDO active this after testing
            }
            this.loadRepairers();
        },
        components: {
            NumberFormatter,
            //GoogleLocation,
            GoodWizard,
            Multiselect,
        }
    };

</script>

<template>
    <div class="claimlinq-claim-new">
        <div class="page-header">
            <h4>Create New Claim</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Create New Claim</li>
            </ol>
        </div>
        <div class="claimlinq-claim-new-body">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="newClaimStepper"
            >
                <div slot="claim-new-step-1">
                    <div class="header">
                        Vehicle Owner
                    </div>
                    <div class="subheader">
                        Add in the vehicle owners’ detail to create a new claim in the system
                        <span class="indicate">
                           * Indicates a mandatory field
                        </span>
                    </div>
                    <div class="claim new-claim-step-vehicle-owner">
                        <div class="claim-block bg-white">
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Vehicle Owner <span class="indicate">*</span></label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.name" class="form-control" placeholder="Vehicle Owner Name">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">
                                    Owner Type <span class="indicate">*</span>
                                </label>
                                <div class="col-sm-7 col-7 ex-owner-type-container">
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.owner.ownerType" class="form-check-input" type="radio" name="ownerType" value="I"
                                        >
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Individual</span>
                                    </label>
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.owner.ownerType" class="form-check-input" type="radio" name="ownerType" value="B"
                                        >
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Business</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Driver Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.driverName" class="form-control" id="driver-name" placeholder="Driver Name">
                                </div>
                            </div>
                            <div v-if="claim.owner.ownerType === 'B'" class="form-group row">
                                <label for="abn" class="col-sm-5 col-5 col-form-label">Business ABN</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.abn" class="form-control" id="abn" placeholder="Business ABN">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Contact Number</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.contact" class="form-control" id="mobile" placeholder="Phone Number">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Email Address</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.email" class="form-control" placeholder="Email Address"
                                    >

                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="levelUnitLot" class="col-sm-5 col-5 col-form-label">Level/Unit/Lot Nbr</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.levelUnitLot" class="form-control" id="levelUnitLot" placeholder="Lot, Unit, or Level number"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="street_name" class="col-sm-5 col-5 col-form-label">Street Nbr/Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.streetName" class="form-control" id="street_name" placeholder="Street Number or Name"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="suburb" class="col-sm-5 col-5 col-form-label">Suburb</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.suburb" class="form-control" id="suburb" placeholder="Suburb">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">State</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            v-model="claim.owner.state"
                                            :options="addressStates"
                                            track-by="value"
                                            label="value"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            placeholder="Select state"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="postcode" class="col-sm-5 col-5 col-form-label">Postcode</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.owner.postcode" class="form-control" id="postcode" placeholder="Postcode">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Country</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            v-model="claim.owner.country"
                                            :options="addressCountries"
                                            track-by="value"
                                            label="value"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            :allow-empty="false"
                                            placeholder="Select country"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="claim-new-step-2">
                    <div class="header">
                        Accident
                    </div>
                    <div class="subheader">
                    </div>
                    <div class="claim new-claim-step-accident">
                        <div class="claim-block bg-white">
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Who was at fault <span class="indicate">*</span></label>
                                <div class="col-sm-7 col-7">
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.fault" class="form-check-input" type="radio" value="claimant"
                                               @click="(claim.accident.fault==='claimant')?claim.accident.fault=null:null">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Claimant</span>
                                    </label>
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.fault" class="form-check-input" type="radio" value="otherParty"
                                               @click="(claim.accident.fault==='otherParty')?claim.accident.fault=null:null">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Other Driver</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Other Driver Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.otherDriverName" class="form-control" placeholder="Other Driver Name">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Other Driver Contact Nbr</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.otherDriverContactNbr" maxlength="20" class="form-control" placeholder="Other Driver Contact Nbr">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Other Driver License Nbr</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.otherDriverLicenseNbr" class="form-control" placeholder="Other Driver License Nbr">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="mobile" class="col-sm-5 col-5 col-form-label">Other Driver Insurer</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.otherDriverInsurer" class="form-control" placeholder="Other Driver Insurer">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Accident Date</label>
                                <div class="col-sm-7 col-7">
                                    <b-form-datepicker
                                            v-model="claim.accident.date"
                                            class="form-control"
                                            locale="en-GB"
                                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                    ></b-form-datepicker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="levelUnitLot" class="col-sm-5 col-5 col-form-label">Level/Unit/Lot Nbr</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.levelUnitLot" class="form-control" placeholder="Lot, Unit, or Level number"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="street_name" class="col-sm-5 col-5 col-form-label">Street Nbr/Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.streetName" class="form-control" placeholder="Street Number or Name"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="suburb" class="col-sm-5 col-5 col-form-label">Suburb</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.suburb" class="form-control" placeholder="Suburb">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">State</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            v-model="claim.accident.state"
                                            :options="addressStates"
                                            track-by="value"
                                            label="value"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            placeholder="Select state"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="postcode" class="col-sm-5 col-5 col-form-label">Postcode</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.postcode" class="form-control" placeholder="Postcode">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Country</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            v-model="claim.accident.country"
                                            :options="addressCountries"
                                            track-by="value"
                                            label="value"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            :allow-empty="false"
                                            placeholder="Select country"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Accident Description</label>
                                <div class="col-sm-7 col-7">
                                    <textarea v-model="claim.accident.description" class="form-control" style="min-height: 100px;" row="10"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Witness Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.witnessName" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Witness Email</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.accident.witnessEmail" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="suburb" class="col-sm-5 col-5 col-form-label">Is there a police report</label>
                                <div class="col-sm-7 col-7">
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.isPoliceReport" class="form-check-input" type="radio" :value="true">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                                    </label>
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.isPoliceReport" class="form-check-input" type="radio" :value="false">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="suburb" class="col-sm-5 col-5 col-form-label">Is Towing required</label>
                                <div class="col-sm-7 col-7">
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.isTowing" class="form-check-input" type="radio" :value="true">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                                    </label>
                                    <label class="form-check-inline radio">
                                        <input v-model="claim.accident.isTowing" class="form-check-input" type="radio" :value="false">
                                        <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Select repairer</label>
                                <div class="col-sm-7 col-7">
                                    <select class="form-control" v-model="claim.innerRepairerId">
                                        <option :value="null"></option>
                                        <option v-for="repairer in this.repairers" key="repairer.id" :value="repairer.id">{{ repairer.businessName }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div slot="claim-new-step-3">
                    <div class="header">
                        Vehicle Information
                    </div>
                    <div class="subheader">
                        Add the owners vehicle details.
                    </div>
                    <div class="claim new-claim-step-vehicle">
                        <div class="claim-block bg-white">
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Rego</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.rego" @input="claim.vehicle.rego=$event.target.value.toUpperCase()" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">D.O.M</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.dom" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Make</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.make" @input="claim.vehicle.make=$event.target.value.toUpperCase()" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Model</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.model" @input="claim.vehicle.model=$event.target.value.toUpperCase()" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Series</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.series" @input="claim.vehicle.series=$event.target.value.toUpperCase()" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Schedule Number</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.scheduleNumber" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Badge</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.badge" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Colour</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.colour" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Colour Variant</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.colourVariant" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Body</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.body" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">VIN</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="claim.vehicle.vin" class="form-control">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Odometer</label>
                                <div class="col-sm-7 col-7">
                                    <number-formatter
                                            type="text"
                                            v-model="claim.vehicle.odometer"
                                            class="form-control numeric"
                                            format="0,0"
                                    ></number-formatter>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-5 col-5 col-form-label">Transmission</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            v-model="claim.vehicle.transmission"
                                            :options="['Automatic','Manual']"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            :allow-empty="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </good-wizard>
            <div class="wizard__buttons">
                <button v-if="$refs.newClaimStepper && $refs.newClaimStepper.currentStep != 0"
                        class="btn btn-outline-primary button-back"
                        type="button"
                        @click="$refs.newClaimStepper.goBack()">
                    Back
                </button>
                <button v-if="$refs.newClaimStepper && $refs.newClaimStepper.currentStep == 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.newClaimStepper && ($refs.newClaimStepper.currentStep != 0 && $refs.newClaimStepper.currentStep !== 1)"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>
                <button v-if="$refs.newClaimStepper && $refs.newClaimStepper.currentStep != 2"
                        class="btn btn-primary button-next"
                        type="button"
                        @click="$refs.newClaimStepper.goNext()"
                        :tabindex="4">
                    Next
                </button>
                <button v-if="$refs.newClaimStepper && $refs.newClaimStepper.currentStep == 2"
                        class="btn btn-primary button-claim"
                        type="button"
                        @click="clickCreateClaim"
                        :disabled="isCreatingClaim"
                >
                    Create Claim
                </button>
            </div>
        </div>
    </div>
</template>
<style>
    .V3 .new-claim {
        max-width: 1028px;
        margin: 0 auto;
    }


</style>
<style lang="scss" scoped>
    .claimlinq-claim-new-body ::v-deep .wizard__steps {
        width: 100%;
        max-width: 900px;
    }

</style>
<style scoped>

    .V3 .claimlinq-claim-new-body > .claim-block {
        padding: 0px;
    }

    .V3 .claimlinq-claim-new-body .claim-block {
        padding: 0px;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        padding: 15px 0px 75px 0px;
        flex-flow: row nowrap;
        justify-content: flex-end;
        max-width: 573px;
        margin: 0 auto;
    }

    .V3 .wizard__buttons .btn {
        flex: 0 1 120px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }

    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }

    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }

    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28, 31, 57, 0.25);
        color: #1C1F39;
    }

    .V3 .wizard__buttons .button-cancel:hover,
    .V3 .wizard__buttons .button-cancel:focus {
        background-color: rgba(28, 31, 57, 0.62);
        color: white;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 15px 15px 75px 15px;
            width: 100%;
        }

        .V3 .wizard__buttons .btn {
            flex-grow: 1;
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }

        .V3 .wizard__buttons .button-claim-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen  and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }
</style>
