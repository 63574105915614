<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Insurer</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Insurance Repair Job</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="insurer.insuranceRepairJob"  :disabled="isReadonly" class="form-check-input" type="radio" value="true"  @click="(!!insurer.insuranceRepairJob)?insurer.insuranceRepairJob=null:null"  @change="insurer.insuranceRepairJob=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>
                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="insurer.insuranceRepairJob" :disabled="isReadonly"  class="form-check-input" type="radio" value="false"  @click="(!insurer.insuranceRepairJob)?insurer.insuranceRepairJob=null:null"  @change="insurer.insuranceRepairJob=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Insurer Name</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="insurer.insurerName" class="form-control"  :readonly="isReadonly">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Claim No</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="insurer.claimNumber" class="form-control"  :readonly="isReadonly">
                </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-4 col-form-label">Policy No</label>
              <div class="col-sm-8 col-8">
                <input type="text" v-model="insurer.policyNumber" class="form-control"  :readonly="isReadonly">
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Claim Type</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="insurer.claimType" class="form-control"  :readonly="isReadonly">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Total Loss</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="insurer.totalLoss"  :disabled="isReadonly" class="form-check-input" type="radio" value="true"   @click="(!!insurer.totalLoss)?insurer.totalLoss=null:null"  @change="insurer.totalLoss=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="insurer.totalLoss" :disabled="isReadonly" class="form-check-input" type="radio" value="false"   @click="(!insurer.totalLoss)?insurer.totalLoss=null:null"  @change="insurer.totalLoss=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Insurer Location</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="insurer.insurerLocation" class="form-control"  :readonly="isReadonly">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Excess Amt</label>
                <div class="col-sm-8 col-8">
                    <number-formatter
                            type="text"
                            v-model="insurer.excess"
                            class="form-control numeric"
                            format="$0,0.00"
                            :disabled="isReadonly"
                    ></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Contribution Amt</label>
                <div class="col-sm-8 col-8">
                    <number-formatter
                            type="text"
                            v-model="insurer.contribution"
                            class="form-control numeric"
                            format="$0,0.00"
                            :disabled="isReadonly"
                    ></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Discount</label>
                <div class="col-sm-8 col-8">
                    <number-formatter
                            v-model="insurer.discount"
                            type="text"
                            class="form-control "
                            maxlength="3"
                            placeholder="Customer discount"
                            format="0%"
                            :percentage="true"
                            :disabled="isReadonly"
                    ></number-formatter>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Repairer Collects Excess</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="insurer.repairerCollectsExcess" :disabled="isReadonly" class="form-check-input" type="radio" value="true"  @click="(!!insurer.repairerCollectsExcess)?insurer.repairerCollectsExcess=null:null" @change="insurer.repairerCollectsExcess=true">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Yes</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="insurer.repairerCollectsExcess" :disabled="isReadonly" class="form-check-input" type="radio" value="false"  @click="(!insurer.repairerCollectsExcess)?insurer.repairerCollectsExcess=null:null" @change="insurer.repairerCollectsExcess=false">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">No</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    import NumberFormatter from '@/components/number-formatter';

    export default {
        name: 'block-claims-insurer',
        props: {
            insurer:
                {
                    type: Object,
                    default: function () {
                        return {
                            insuranceRepairJob: null,
                            policyNumber: '',
                            claimNumber: '',
                            claimType: '',
                            totalLoss: '',
                            name: '',
                            location: '',
                            personallyInjured: '',
                            excess: '',
                            contribution: '',
                            discount: '',
                            repairerCollect: null,
                            repairerCollectsExcess: null,
                        };
                    },
                },
            isReadonly:{
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {};
        },

        components: {
            NumberFormatter
        }

    };
</script>
<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
