<template>

  <div class="card">
    <div v-if="!hideHeader" class="card-header bg-navyblue">
      <strong>Referrer</strong>
    </div>
    <div class="card-block bg-white">
      <div class="form-group row">
        <label class="col-sm-4 col-4 col-form-label">Referred By</label>
        <div class="col-sm-8 col-8">
          <input type="text" v-model="referrer.referrerBy" class="form-control">
        </div>
      </div>
      <div class="form-group row">
        <label for="owner" class="col-sm-4 col-4 col-form-label">Referrer Name</label>
        <div class="col-sm-8 col-8">
          <advanced-search
                           searchLabel="Referrer"
                           v-model="computedReferrer"
                           :searchData="referrers"
                           searchKey="name"
                           @onSelect="onReferrerSelect"
                           placeholderText="Referrer"
          >
          </advanced-search>
        </div>
      </div>
      <div class="form-group row">
        <label for="owner" class="col-sm-4 col-4 col-form-label">Date Referred</label>
        <div class="col-sm-8 col-8">
          <b-form-datepicker
                  id="dateReferred"
                  v-model="referrer.date"
                  class="mb-2"
                  locale="en-GB"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label for="owner" class="col-sm-4 col-4 col-form-label">Amount to Pay</label>
        <div class="col-sm-8 col-8">
          <number-formatter
                  id="assessmentValue"
                  v-model="referrer.amountToPay"
                  type="text"
                  class="form-control numeric"
                  format="$0,0.00"
          ></number-formatter>
        </div>
      </div>
      <div class="form-group row">
        <label for="owner" class="col-sm-4 col-4 col-form-label">Date Paid</label>
        <div class="col-sm-8 col-8">
          <b-form-datepicker
                  id="datePaidReferred"
                  v-model="referrer.paidDate"
                  class="mb-2"
                  locale="en-GB"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
          ></b-form-datepicker>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

  import AdvancedSearch from "@/components/utility/advanced-search";
  import NumberFormatter from "@/components/number-formatter";

  export default {
  name: "block-referrer",
  components: {
    AdvancedSearch,
    NumberFormatter,
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    'onReferrerChanged': Function,
    referrers: {
      type: Array,
      default: () => [],
    },
    referrer: {
      type: Object,
      default: () => {
        return {
          referrerId: null,
          referrerBy: '',
          name: '',
          date: '',
          amountToPay: '',
          paidDate: '',
        };
      }
    },
  },
  data: function (){
    return {
      localReferrerName: '',
    }
  },
  watch: {
    'referrer.referrerId': function (newVal) {
      //console.log("UPDREF", this.referrer);
      if (this.referrers && this.referrers.length > 0 && this.referrer.referrerId > 0) {
        let r = _.find(this.referrers, item => {
          return Number(item.id) === Number(newVal);
        });
        if (r && r.name) {
          this.localReferrerName = r.name;
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.referrers && this.referrers.length > 0 && this.referrer.referrerId > 0) {
        let r = _.find(this.referrers, item => {
          return Number(item.id) === Number(this.referrer.referrerId);
        });
        if (r && r.name) {
          this.localReferrerName = r.name;
        }
      }
    })
  },
  computed: {
    computedReferrer: {
      get() {
        return this.localReferrerName;
      },
      set(val) {
       // console.log('computedReferrer', val);
        this.localReferrerName = val;
      },
    },
    selectedReferrer: {
      get(){
        let r = null;
        if(!this.referrer.referrerId){
          return null;
        }
        this.referrers.forEach(rr => {
          if(r) return;
          if(rr.id == this.referrer.referrerId){
            r = rr;
          }
        })
        return r;
      }
    }
  },
  methods: {
    onReferrerSelect(val) {
      if (val) {
        if(val.id == this.referrer.referrerId){
          this.localReferrerName = val.name;
        }
        this.referrer.referrerId = val.id;
        this.onReferrerChanged(val);
      } else {
        this.onReferrerChanged({
          id: null,
          name: this.localReferrerName,
        });
      }
    },
  },
}
</script>

<style scoped>

  .V3 body.V3 .multiselect__select {
    height: 35px;
    right: 0px;
    top: 0px;
  }

  .V3 .col-form-label {
    padding: 8px 0;
  }

  @media (max-width: 575.98px) {
    .V3 .col-block .col-form-label {
      padding: 8px 15px;
    }
  }
</style>
