<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <block-other-party-details :details="otherParty.details">

                </block-other-party-details>
            </div>
            <div class="col-md-4">
                <block-other-party-vehicle :vehicle="otherParty.vehicle">

                </block-other-party-vehicle>
            </div>
            <div class="col-md-4">
                <other-party-rental-context-tab
                        @change-active-rental-tab="changeActiveRentalTab"
                        :rentalTotal="otherParty.rentalTotal"
                        :rental="otherParty.rental"
                        :rentals="rentals"
                        :otherParty="otherParty"
                        :claim="claim"
                        :assessment="assessment"
                        v-on="$listeners"
                        :referrers="referrers"
                        :referrer="otherParty.referrer"
                        :on-referrer-changed="onReferrerChangedLocal"
                        :settled="otherParty.settled"
                >
                </other-party-rental-context-tab>
                <progress-bar
                        v-if="isShowProgressBar"
                        :current-step="currentStatus * 1"
                        :steps="statusesList"
                        :showing-count="3"
                        title="Progress"
                        :link="{
                          text: 'Full Activity',
                          href: '#',
                          isGoToActivityRef: true,
                        }"
                        v-on="$listeners"
                ></progress-bar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <block-claims-insurer :insurer="otherParty.insurer">

                </block-claims-insurer>
            </div>
            <div class="col-md-4">
                <block-claims-repairer-new @on-repairer-select="onRepairerUpdate"
                                           :inner-repairer="otherParty.innerRepairer || undefined"
                                           :estimators="estimators"
                                           :estimator="otherParty.estimator"
                                           :repairers="repairers">
                </block-claims-repairer-new>
            </div>
            <div class="col-md-4">
                <block-other-party-repairer-quote :repairerQuote="otherParty.repairerQuote" :claim="claim" :settled="otherParty.settled">

                </block-other-party-repairer-quote>
                <block-assessment-information
                    :assessment="assessment"
                    :isShow="(assessment && assessment.type==='other_party')"
                    :with-recoveries-on="claim.withRecoveriesOn"
                    :claim="claim"
                    :settled="otherParty.settled"
                    @with-recoveries-on-changed="withRecoveriesOnChanged"
                >
                </block-assessment-information>

                <block-rental-information
                    :rentals="rentals"
                    :rental-total="otherParty.rentalTotal"
                    :vehicle-owner="otherParty.details.vehicleOwner"
                    :settled="otherParty.settled"
                ></block-rental-information>

                <block-personal-injury-information :settled="otherParty.settled"></block-personal-injury-information>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8 ">
                <block-accident-details :details="otherParty.accident.details">

                </block-accident-details>
            </div>
            <div class="col-md-4">
                <block-other-party-statement :statement="otherParty.statement" @saveSignature="saveSignature">
                </block-other-party-statement>

                <block-accident-towing :towing="otherParty.accident.towing">
                </block-accident-towing>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import OtherPartyRentalContextTab from "./other-party-rental-context-tab";
    import BlockOtherPartyStatement from "../../../../components/blocks/claim/statement";
    import BlockOtherPartyDetails from "../../../../components/block-other-party-details";
    import BlockVehicle from "../../../../components/block-vehicle";
    import BlockClaimsInsurer from "../../../../components/block-claims-insurer";
    import BlockClaimsRepairer from "../../../../components/block-claims-repairer";
    import BlockClaimsRepairerNew from "../../../../components/block-claims-repairer-new";
    import BlockAccidentDetails from "../../../../components/block-accident-details";
    import BlockAccidentTowing from "../../../../components/block-accident-towing";
    import BlockOtherPartyRepairerQuote from "../../../../components/block-other-party-repairer-quote";
    import BlockAssessmentInformation from "../../../../components/block-assessment-information";
    import BlockOtherPartyVehicle from "../../../../components/block-other-party-vehicle";
    import ProgressBar from "@/components/ProgressBar";
    import BlockRentalInformation from "@/components/block-rental-information";
    import BlockPersonalInjuryInformation from "@/components/block-personal-injury-information";
    import Axios from "axios";

    export default {
        name: 'claim-view-other-party-tab-other-party',
        props: {
            'claim': [Object, null],
            'currentStatus':[Number, String, null],
            'statusesList':[Array, null],
            'otherParty': Object,
            'assessment': [Object, null],
            'repairers': Array,
            'estimators': Array,
            rentals: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            'referrers': Array|null,
            'onReferrerChanged': Function,
        },
        data: function () {
            return {
                activeRentalTab:'',
            };
        },
        computed: {
            isShowProgressBar() {
                if (this.activeRentalTab == '#reasons-for-hire' || this.activeRentalTab == '#reasons-for-rental' ) {
                    return false;
                }
                return true;
            },
            getCurrentRentals(){
              let rs = [];
              for(let rental of this.rentals){
                if(rental.details.hirerName == '') rs.push(rental);
              }
              return rs;
            },
            //countOtherParty() {
            //    return this.otherParties.length || 0;
            //}
        },
        methods: {
            saveSignature(signature) {
                this.$emit('saveSignature', {objectId: this.otherParty.id, signature: signature});
            },
            changeActiveRentalTab(tabHash) {
                this.activeRentalTab = tabHash;
            },
            onReferrerChangedLocal(r){
                console.log('r', r);
                if (r && r.id) {
                    // this.insured.referrer.referrerId = r.id;
                } else {
                    this.otherParty.referrer.referrerId = false;
                    this.otherParty.referrer.referrerName = r.name;
                    this.onReferrerChanged()
                }
            },
          withRecoveriesOnChanged(newVal){
            this.claim.withRecoveriesOn = newVal;
          },
          onRepairerUpdate(repairer){
            Axios({
              method: 'post',
              responseType: 'json',
              url: `/ir/claim/${this.claim.id}`,
              data: {
                otherParties: [{
                  id: this.otherParty.id,
                  repairer: {
                    innerRepairerId: repairer.id
                  }
                }]
              },
            })
                .then(response => {
                  if (response && response.status === 200 && response.data && response.data._status) {
                    this.$emit('on-other-party-repairer-updated');
                  }
                })
                .catch(error => console.log(error));
          }
        },
        components: {
            OtherPartyRentalContextTab,
            BlockOtherPartyVehicle,
            BlockOtherPartyRepairerQuote,
            BlockAccidentDetails,
            BlockAccidentTowing,
            BlockClaimsRepairer,
            BlockClaimsRepairerNew,
            BlockClaimsInsurer,
            BlockVehicle,
            BlockOtherPartyDetails,
            BlockOtherPartyStatement,
            BlockAssessmentInformation,
            ProgressBar,
            BlockRentalInformation,
            BlockPersonalInjuryInformation,
        }
    };
</script>
