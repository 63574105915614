<template>
    <div class="sub-tabs-component insured-rental-context-tab">
        <tabs ref="claim-view-insured-rental-context-tab" @changed="changedTab" :options="{ defaultTabHash:'claim-type' }">
            <!--    eslint-disable-->
<!--            <tab name="Reasons for Rental" id="reasons-for-rental">-->
<!--            </tab>-->
            <tab name="Claim Type" id="claim-type">
                <block-claim-type
                        :rentalTotal="rentalTotal"
                        :hideHeader="true"
                        :claim="claim"
                        :rentals="rentals"
                        :assessment-subtotal="assessment ? assessment.subtotal : 0"
                        v-on="$listeners"
                ></block-claim-type>
            </tab>
            <tab name="Rental Req" id="rental-req">
              <block-rental-requirement
                  :hideHeader="true"
                  :rental="rental"
                  :rentals="rentals">
              </block-rental-requirement>
            </tab>
            <tab name="Reasons for Rental" id="reasons-for-rental">
                <block-reasons-for-rental :hideHeader="true" :reasons="reasons">

                </block-reasons-for-rental>
            </tab>
            <tab name="Referrer" id="referrer">
                <block-referrer
                        :hideHeader="true"
                        :referrers="referrers"
                        :referrer="referrer"
                        :on-referrer-changed="onReferrerChanged"
                ></block-referrer>
            </tab>

          <tab name="Settlement" id="settlement">
            <block-settlement
                :rental-total="rentalTotal || null"
                :assessment-subtotal="assessment ? assessment.subtotal : 0"
                :settled="settled"
            ></block-settlement>
          </tab>

          <tab name="Additionals" id="additionals">
            <block-additionals :additionals="additionals" :additionals-to-delete="additionalsToDelete"></block-additionals>
          </tab>
        </tabs>
    </div>
</template>
<script>
    /* eslint-disable */
    import BlockRentalRequirement from "../../../../components/block-rental-requirement";
    import BlockClaimType from "../../../../components/block-claim-type";
    import BlockReasonsForRental from "../../../../components/block-reasons-for-rental";
    import BlockSettlement from "../../../../components/block-settlement";
    import BlockReferrer from "@/components/block-referrer";
    import BlockAdditionals from "@/components/blocks/claim/additionals";

    export default {
        name: 'claim-view-insured-rental-context-tab',
        props: {
            rentalTotal: [Number, null],
            reasons: [Object, null],
            rental: {
                type: Object,
                default: function () {
                    return {
                        requirersRental: null,
                        asap: null,
                        agrmntNbr: '',
                        company: '',
                    };
                }
            },
            rentals: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            claim: {
                type: Object,
                default: {
                    type_diminished_value: 0,
                    type_rectification: 0,
                    type_rental: 0,
                    type_repairers: 0,
                    totalAmountSettled: 0,
                },
            },
            assessment: {},
            referrers: Array | null,
            onReferrerChanged: Function,
            referrer: Object,
            settled: {},
            additionals: {
              type: [Array, null],
              default() {
                return [];
              }
            },
            additionalsToDelete: {
              type: [Array, null],
              default() {
                return [];
              }
            },
        },
        data: function () {
            return {};
        },
        computed: {},
        methods: {
            changedTab: function (obj) {
                this.$emit('change-active-rental-tab', obj.tab.hash);
            },
        },
        components: {
            BlockRentalRequirement,
            BlockClaimType,
            BlockReasonsForRental,
            BlockReferrer,
            BlockSettlement,
            BlockAdditionals,
        },

    };
</script>

<style lang="scss" scoped>
   //.insured-rental-context-tab ::v-deep .tabs-component::after{
   //     /*display:none;*/
   //    height:0px;
   // }
   .sub-tabs-component.insured-rental-context-tab ::v-deep .tabs-component {
       position: inherit;
   }
</style>
