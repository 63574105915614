<template>
    <div class="dashboard-page">
        <div class="page-header">
            <h4>Dashboard</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </div>
        <div class="widget-panel white-bg mb-0 ">
            <widget-claims :claims="claims" />
        </div>
        <div class="widget-panel white-bg mb-0 ">
            <widget-tabs :claims="claims" :assessments="assessments" :rentals="rentals" :months="months" />
        </div>
        <div class="widget-container">
            <div class="widget-panel parts-order-panel">
                <!--                <PartsOrders />-->
            </div>
            <div class="widget-panel notifications-panel">
                <!--                <Notifications />-->
            </div>
        </div>
    </div>
</template>

<script>

    import WidgetClaims from "./../dashboard-widgets/claims";
    import WidgetTabs from "./../dashboard-widgets/tabs";
    import Axios from "axios";

    export default {
        name: "DashboardForAccidentManagement",
        data() {
            return {
                assessments: {
                    new: {
                        count: 0,
                        sum: 0,
                    },
                    processing: {
                        count: 0,
                        sum: 0,
                    }
                },
                claims: {
                    approved: {
                        count: 0,
                        sum: 0,
                    },
                    denied: {
                        count: 0,
                        sum: 0,
                    },
                    new: {
                        count: 0,
                        sum: 0,
                    },
                    settled: {
                        count: 0,
                        sum: 0,
                    },
                    withRecoveries: {
                        count: 0,
                        sum: 0,
                    },
                },
                months: {},
                rentals: {
                    count: 0,
                    sum: 0,
                },
            };
        },
        methods: {
            loadData() {
                NProgress.start();
                Axios.get('/ir/dashboard').then(response => {
                    this.assessments = response.data.assessments;
                    this.claims      = response.data.claims;
                    this.months      = response.data.months;
                    this.rentals     = response.data.rentals;
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    NProgress.done();
                });
            },
        },
        mounted() {
          this.loadData();
        },
        components: {
            WidgetClaims,
            WidgetTabs,
        }
    };
</script>
