<script>
    /* eslint-disable */
    import {mapGetters} from 'vuex';
    import Axios from 'axios';

    export default {
        name: 'block-progress',
        props: {

        },
        data: function () {
            return {
            };
        },
        methods: {
        },

        computed: {
        },
        watch: {},
        components: {
        }

    };
</script>
<template>
    <div class="card">

    </div>
</template>

