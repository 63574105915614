<script>
    /* eslint-disable */

    import BlockAddress from "./block-address";

    export default {
        name: 'block-accident-details',
        props: {
            details: {
                type: Object,
                default: () => ({
                    accidentAtFault: '',
                    accidentDate: null,
                    accidentNotAtFault: '',
                    description: '',
                    photosUploaded: null,
                    policeReport: null,
                    policeReportLocation: '',
                    witnessEmail: '',
                    witnessName: '',
                    witnessPhone: '',
                    contactAboutInjury: null,
                    personallyInjured: null,
                    address: {}
                })
            },
            isEditable: {
                type: Boolean,
                default() {
                    return true;
                }
            }
        },
        data: function () {
            return {};
        },
        methods: {},

        computed: {},
        watch: {},
        components: {BlockAddress}

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Accident</strong>
        </div>
        <div class="card-block bg-white">
            <div class="row no-gutter">
                <div class="col-sm-6 col-12 pl-sm-0">
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Accident at Fault</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.accidentAtFault" class="form-control" :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Accident Not at Fault</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.accidentNotAtFault" class="form-control"  :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Accident Date</label>
                        <div class="col-sm-8 col-8">
                            <b-form-datepicker
                                    v-model="details.accidentDate"
                                    class="mb-2 "
                                    locale="en-GB"
                                    :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                    :disabled="!isEditable"
                            ></b-form-datepicker>
                        </div>
                    </div>
                    <block-address :isReadonly="!isEditable" :address="details.address">

                    </block-address>
                </div>
                <div class="col-sm-6 col-12 pr-sm-0">
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Accident Description</label>
                        <div class="col-sm-8 col-8">
                            <textarea type="text" v-model="details.description" class="form-control" rows="8"  :readonly="!isEditable"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Photos Uploaded</label>
                        <div class="col-sm-8 col-8">
                            <label class="form-check-inline radio">
                                <input v-model="details.photosUploaded" class="form-check-input" type="radio" value="true"
                                       @click="(!!details.photosUploaded)?details.photosUploaded=null:null"
                                       @change="details.photosUploaded=true"
                                       :disabled="!isEditable">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>

                            </label>
                            <label class="form-check-inline radio">
                                <input v-model="details.photosUploaded" class="form-check-input" type="radio" value="false"
                                       @click="(!details.photosUploaded)?details.photosUploaded=null:null"
                                       @change="details.photosUploaded=false"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Witness Name</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.witnessName" class="form-control"  :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Witness Nbr</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.witnessPhone" class="form-control"  :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Witness Email</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.witnessEmail" class="form-control"  :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Police Report</label>
                        <div class="col-sm-8 col-8">
                            <label class="form-check-inline radio">
                                <input v-model="details.policeReport" class="form-check-input" type="radio" value="true"
                                       @click="(!!details.policeReport)?details.policeReport=null:null"
                                       @change="details.policeReport=true"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>

                            </label>
                            <label class="form-check-inline radio">
                                <input v-model="details.policeReport" class="form-check-input" type="radio" value="false"
                                       @click="(!details.policeReport)?details.policeReport=null:null"
                                       @change="details.policeReport=false"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Report Location</label>
                        <div class="col-sm-8 col-8">
                            <input type="text" v-model="details.policeReportLocation" class="form-control"  :readonly="!isEditable">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Personally injured</label>
                        <div class="col-sm-8 col-8">
                            <label class="form-check-inline radio">
                                <input v-model="details.personallyInjured" class="form-check-input" type="radio"
                                       :value="true"
                                       @click="(!!details.personallyInjured)?details.personallyInjured=null:null"
                                       @change="details.personallyInjured=true"
                                       :disabled="!isEditable">
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>

                            </label>
                            <label class="form-check-inline radio">
                                <input v-model="details.personallyInjured" class="form-check-input" type="radio"
                                       :value="false"
                                       @click="(!details.personallyInjured)?details.personallyInjured=null:null"
                                       @change="details.personallyInjured=false"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-4 col-form-label">Contact About Injury</label>
                        <div class="col-sm-8 col-8">
                            <label class="form-check-inline radio">
                                <input v-model="details.contactAboutInjury" class="form-check-input" type="radio"
                                       :value="true"
                                       @click="(!!details.contactAboutInjury)?details.contactAboutInjury=null:null"
                                       @change="details.contactAboutInjury=true"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">Yes</span>

                            </label>
                            <label class="form-check-inline radio">
                                <input v-model="details.contactAboutInjury" class="form-check-input" type="radio"
                                       :value="false"
                                       @click="(!details.contactAboutInjury)?details.contactAboutInjury=null:null"
                                       @change="details.contactAboutInjury=false"
                                       :disabled="!isEditable"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span>
                                <span class="text">No</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }

    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label {
            padding: 8px 15px;
        }
    }
</style>
