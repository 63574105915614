<script>
    /* eslint-disable */

    export default {
        name: 'block-vehicle',
        props: {
            vehicle: {
                type: Object,
                default: function () {
                    return {
                        rego: '',
                        make: '',
                        model: '',
                        series: '',
                        badge: '',
                        body: '',
                        dom: '',
                        vin: '',
                        colour: '',
                        colourVariant: '',
                        odometer: '',
                        transmission: '',
                    };
                }
            },
        },
        data: function () {
            return {
            };
        },
        methods: {
},

        computed: {
},
        watch: {},
        components: {
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Vehicle</strong>
        </div>
        <div class="card-block bg-white">

            <div class="form-group row">
                <label for="rego" class="col-sm-4 col-4 col-form-label">Rego</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.rego" class="form-control" placeholder="Rego" readonly>
                </div>
            </div>

            <div class="form-group row">
                <label for="make" class="col-sm-4 col-4 col-form-label">Make</label>
                <div class="col-sm-8 col-8" ref="multi1">
                    <input type="text" v-model="vehicle.make" class="form-control" placeholder="Make" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label for="model" class="col-sm-4 col-4 col-form-label">Model</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.model" class="form-control" placeholder="Model" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label for="series" class="col-sm-4 col-4 col-form-label">Series</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.series" class="form-control" placeholder="Series" readonly>
                </div>
            </div>


            <div class="form-group row">
                <label for="badge" class="col-sm-4 col-4 col-form-label">Badge</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.badge" class="form-control" placeholder="Badge" readonly>
                </div>
            </div>

            <div class="form-group row">
                <label for="body" class="col-sm-4 col-4 col-form-label">Body</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.body" class="form-control" placeholder="Body" readonly>
                </div>
            </div>

            <div class="form-group row">
                <label for="dom" class="col-sm-4 col-4 col-form-label">D.O.M</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.dom" class="form-control" placeholder="D.O.M" readonly>
                </div>
            </div>

            <div class="form-group row">
                <label for="vin" class="col-sm-4 col-4 col-form-label">VIN</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.vin" class="form-control text-uppercase" placeholder="VIN" readonly>
                </div>
            </div>

            <div class="form-group row">
                <label for="colour" class="col-sm-4 col-4 col-form-label">Colour</label>
                <div class="col-sm-8 col-8">

                    <input type="text" v-model="vehicle.colour" class="form-control" placeholder="Colour" readonly>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Colour Variant</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.colourVariant" class="form-control" placeholder="Colour Variant" readonly>
                </div>
            </div>


            <div class="form-group row">
                <label for="odometer" class="col-sm-4 col-4 col-form-label">Odometer</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.odometer" class="form-control" placeholder="Odometer" readonly>
                </div>
            </div>


            <div class="form-group row">
                <label for="transmission" class="col-sm-4 col-4 col-form-label">Transmission</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="vehicle.transmission" class="form-control" placeholder="Transmission" readonly>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
      padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
