<template>
    <div class="dashboard-panel card" v-bind:style="{'max-width':width || '100%'}">
        <div class="card-body">
            <div class="card-title">
                <h4 class="title-text">Claims</h4>
                <div class="search grid">
                    <div class="input-text">

                    </div>
                    <router-link :to="{name:'ClaimNew'}" class="btn btn-primary" style="vertical-align: middle;">
                        <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>
                        <span style="vertical-align: middle;">Create Claim</span>
                    </router-link>
                </div>
            </div>
            <div class="cards-list">
                <router-link :to="{name:'Claims', query: {filter: '#NewClaim'}}">
                    <div class="card-item">
                        <div class="header-item">
                            New
                            <i class="bx bx-link-external clickable"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{getterCountNewClaims}}</span> New Claims
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Claims', query: {filter: '#Approved'}}">
                    <div class="card-item">
                        <div class="header-item">
                            Approved
                            <i class="bx bx-link-external clickable" style="color:#00B050"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{getterCountApprovedClaims}}</span> Approved Claims
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Claims', query: {filter: '#WithRecoveries'}}">
                    <div class="card-item">
                        <div class="header-item">
                            W/Recoveries
                            <i class="bx bx-dollar-circle clickable" style="color:#00B050"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{getterCountWRecoveriesClaims}}</span> With Recoveries
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
                <router-link :to="{name:'Claims', query: {filter: '#ClaimDenied'}}">
                    <div class="card-item">
                        <div class="header-item">
                            Denied
                            <i class='bx bx-x-circle' style="color:red"></i>
                        </div>
                        <div class="body-item">
                            <div class="list-values ">
                                <div class="list-values-header claimlinq-bg clickable">
                                    <span class="numb">{{getterCountDeniedClaims}}</span> Denied
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import url(../../../styles/dashboard/style.css);

    .list-values .clickable:hover {
        color: #000000;
    }

    .card-item .body-item {
        min-height: 100px;
    }
</style>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "WidgetClaims",
        props: {
            width: String,
            claims: {
                type: Object,
                default() {
                    return {
                        approved: {
                            count: 0,
                        },
                        denied: {
                            count: 0,
                        },
                        new: {
                            count: 0,
                        },
                        withRecoveries: {
                            count: 0,
                        },
                    };
                }
            },
        },
        methods: {},
        computed: {
            ...mapGetters({
                getterCountNewClaims: "claim/countNewClaims",
                getterCountApprovedClaims: "claim/countApprovedClaims",
                getterCountWRecoveriesClaims: "claim/countWRecoveriesClaims",
                getterCountDeniedClaims: "claim/countDeniedClaims",
            }),
        },
        components: {}
    };
</script>
