<template>
    <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element">
        <div class="row">
            <div class="col-12 cls-sm-12 col-md-12 col-lg-8">
                <span class="text-overflow"> From: {{ email.from }}</span>

            </div>
            <div class="col-12 cls-sm-12 col-md-12 col-lg-4" style="text-align: right;">
                <strong style="margin-right: 10px;"> - Received {{ email.createdOn | formatTime }}</strong>
                <i class='bx bx-envelope theme-color pull-right'></i>
            </div>
        </div>
        <div class="row mt-0-5">
            <div class="col" style="text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;">
                Subject: {{ email.subject }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "email-summary",
        props: {
            email: {
                type: [Object, null],
                default() {
                    return {
                        "id": 0,
                        "recipient": "test@claimlinq.com.au",
                        "html_text": "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
                        "subject": "Email subject for A00311",
                        "timestamp": null,
                        "created_on": "2021-10-15 13:00:35",
                        "createdOn": "2021-10-15 13:00:35",
                        "from": "test@test.ru",
                        "in_out": "in",
                        "files": []
                    };
                }
            },
        },
    };
</script>

<style scoped>
    .text-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75ch;
    }
</style>
