import { render, staticRenderFns } from "./insured-rental-context-tab.vue?vue&type=template&id=4275c067&scoped=true"
import script from "./insured-rental-context-tab.vue?vue&type=script&lang=js"
export * from "./insured-rental-context-tab.vue?vue&type=script&lang=js"
import style0 from "./insured-rental-context-tab.vue?vue&type=style&index=0&id=4275c067&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4275c067",
  null
  
)

export default component.exports