<template>

    <div class="card">

        <div class="card-block bg-white">

            <div v-for="add of additionals" v-bind:key="add.id">

                <div class="row" v-show="!isExpanded(add.id)" @click="clickExpand(add.id)">
                    <div class="col-7" style="vertical-align: center; font-weight: bold;">
                        <span v-if="add.type == 'service'">Service</span>
                        <span v-if="add.type == 'autobody_repair'">Service - Autobody Rpr</span>
                        <span v-if="add.type == 'product'">Product</span>
                        <template v-if="add.businessName != ''"> — {{ add.businessName }}</template>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-link text-danger p-2" @click="removeAdditional(add)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button class="btn btn-link theme-color p-2" style="font-weight: bold;">
                            Expand <i class="fa fa-chevron-down"></i>
                        </button>
                    </div>
                </div>
                <div class="row" v-show="isExpanded(add.id)" @click="clickCollapse(add.id)">
                    <div class="col-7" style="vertical-align: center; font-weight: bold;">
                        <span v-if="add.type == 'service'">Service</span>
                        <span v-if="add.type == 'autobody_repair'">Service - Autobody Rpr</span>
                        <span v-if="add.type == 'product'">Product</span>
                        <template v-if="add.businessName != ''"> — {{ add.businessName }}</template>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-link text-danger p-2" @click="removeAdditional(add)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button class="btn btn-link theme-color p-2" style="font-weight: bold;">
                            Collapse <i class="fa fa-chevron-up"></i>
                        </button>
                    </div>
                </div>

                <div v-show="isExpanded(add.id)">
                    <br>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Type </label>
                        <div class="col-sm-7 col-7">
                            <select class="form-control" v-model="add.type">
                                <option value="service">Service</option>
                                <option value="autobody_repair">Service - Autobody Rpr</option>
                                <option value="product">Product</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Business name</label>
                        <div class="col-sm-7 col-7">
                            <input type="text" class="form-control" v-model="add.businessName">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Invoice Number</label>
                        <div class="col-sm-7 col-7">
                            <input type="text" class="form-control" v-model="add.invoiceNumber">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Invoice Due Date</label>
                        <div class="col-sm-7 col-7">
                            <b-form-datepicker
                                    v-model="add.invoiceDate"
                                    today-button
                                    reset-button
                                    close-button
                                    class="mb-2"
                                    locale="en-GB"
                                    :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            ></b-form-datepicker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Invoice Amount</label>
                        <div class="col-sm-7 col-7">
                            <number-formatter v-model="add.invoiceAmount" class="form-control numeric" format="$0,0.00"></number-formatter>
                        </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-5 col-5 col-form-label">Amount Is </label>
                      <div class="col-sm-7 col-7">
                        <select class="form-control" v-model="add.tax">
                          <option value="exclusive">Tax Exclusive</option>
                          <option value="inclusive">Tax Inclusive</option>
                          <option value="none">No Tax</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-5 col-5 col-form-label">Tax Rate </label>
                      <div class="col-sm-7 col-7">
                        <select class="form-control" v-model="add.taxRate">
                          <option value="free_expense">GST Free Expense</option>
                          <option value="on_expense">GST On Expense</option>
                        </select>
                        <span v-show="!add.taxRate" class="text-danger">This field is required</span>
                      </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Date Paid</label>
                        <div class="col-sm-7 col-7">
                            <b-form-datepicker
                                    v-model="add.datePaid"
                                    today-button
                                    reset-button
                                    close-button
                                    class="mb-2"
                                    locale="en-GB"
                                    :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            ></b-form-datepicker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Payment Method</label>
                        <div class="col-sm-7 col-7">
                            <input type="text" class="form-control" v-model="add.paymentMethod">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-5 col-5 col-form-label">Comment</label>
                        <div class="col-sm-7 col-7">
                            <textarea rows="4" class="form-control" v-model="add.comment"></textarea>
                        </div>
                    </div>
                </div>
                <hr>

            </div>

            <div class="row">
                <div class="col text-right">
                    <button class="btn btn-link theme-color font-weight-bold" @click="addAdditional">
                        <i class="fa fa-plus"></i> Add Additional
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    /*eslint-disable*/
    import _ from "lodash";
    import NumberFormatter from "@/components/utility/number-formatter";

    export default {
        name: "additionals",
        components: {
            NumberFormatter,
        },
        props: {
            additionals: {
                type: [Array, null],
                default() {
                    return [
                        {
                            id: 1,
                            type: 'service',
                            tax: 'inclusive',
                            taxRate: null,
                            businessName: '',
                            invoiceNumber: '',
                            invoiceDate: '',
                            invoiceAmount: 0,
                            datePaid: '',
                            paymentMethod: 'Credit card',
                            comment: '',
                        },
                        {
                            id: 2,
                            type: 'product',
                            tax: 'inclusive',
                            taxRate: null,
                            businessName: '',
                            invoiceNumber: '',
                            invoiceDate: '',
                            invoiceAmount: 0,
                            datePaid: '',
                            paymentMethod: 'Credit card',
                            comment: '',
                        },
                    ];
                }
            },
            additionalsToDelete: {
                type: [Array, null],
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                expandedIds: [],
                newObj: {},
            };
        },
        watch: {
            'newObj': {
                deep: true,
                handler() {
                    if (this.newObj.id > 0) {
                        this.clickExpand(this.newObj.id);
                        this.newObj = {};
                    }
                }
            },
        },
        methods: {
            isExpanded(id) {
                return !!(_.includes(this.expandedIds, id));
            },
            clickCollapse(id) {
                this.expandedIds = _.filter(this.expandedIds, function (i) {
                    return i != id;
                });
            },
            clickExpand(id) {
                this.expandedIds.push(id);
            },
            addAdditional() {
                this.newObj = {
                    id: 0,
                    type: 'service',
                    tax: 'inclusive',
                    taxRate: null,
                    businessName: '',
                    invoiceNumber: '',
                    invoiceDate: '',
                    invoiceAmount: 0,
                    datePaid: '',
                    paymentMethod: '',
                    comment: '',
                };
                this.additionals.push(this.newObj);
                this.clickExpand(0);
            },
            removeAdditional(add) {
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this', {
                    title: 'Are you sure?',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: 'p-2',
                    centered: true
                })
                    .then(value => {
                        if (!value) return;
                        if (!this.additionalsToDelete) {
                            this.additionalsToDelete = [];
                        }
                        if (add.id == 0) {
                            this.additionals.pop();
                        } else {
                            this.additionalsToDelete.push(add.id);
                            this.additionals.splice(this.additionals.indexOf(add), 1);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },
        },
    };
</script>

<style scoped>

</style>
