<template>
    <div class="dashboard-panel card" v-bind:style="{'max-width':width || '100%'}">
        <tabs :options="{ useUrlFragment: false }" ref="dashboard-widget-tabs">
            <tab name="Settlements"
                 id="dashboard-widget-tabs-settlements-tab"
                 :class-name="'tab-content-table'">
                <widget-settlements-tab
                    :claims="claims"
                    :assessments="assessments"
                    :rentals="rentals"
                    :months="months">
                </widget-settlements-tab>
            </tab>
            <tab name="Repairs"
                 id="dashboard-widget-tabs-repairs-tab"
                 :class-name="'tab-content-table'">

            </tab>
            <tab name="Rentals"
                 id="dashboard-widget-tabs-rentals-tab"
                 :class-name="'tab-content-table'">

            </tab>
        </tabs>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import WidgetSettlementsTab from "./tabs/settlements-tab";

    export default {
        name: "WidgetTabs",
        props: {
            width: String,
            assessments: Object,
            claims: Object,
            rentals: Object,
            months: Object,
        },
        methods: {},
        computed: {
            ...mapGetters({})

        },
        components: {WidgetSettlementsTab}
    };
</script>
