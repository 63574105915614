<template>

  <div class="holder">
    <input
        type="text"
        class="form-control"
        :value="content"
        @input="handleInput($event.target.value)"
        :maxlength="maxlength"
        :disabled="disabled"
        :readonly="readonly"
        ref="input"
        @keydown.enter="acceptNewValue"
        @keydown.esc="discardChanges"
    >
    <div v-show="showDropdown" class="acceptor">
      <a href="#" @click.prevent="acceptNewValue" v-html="acceptationText"></a>
      <a href="#" @click.prevent="discardChanges" v-show="showDiscard">Discard changes</a>
    </div>
  </div>

</template>

<script>
export default {
  name: "input-with-confirmation",
  props: {
    disabled: {
      type: Boolean,
      default(){
        return false;
      }
    },
    readonly: {
      type: Boolean,
      default(){
        return false;
      }
    },
    maxlength: {
      type: [Number, String],
      default(){
        return 524288;
      }
    },
    value: {
      type: String
    },
    acceptText: {
      type: [Function, null],
      default(){
        return null;
      }
    },
    showDiscard: {
      type: Boolean,
      default(){
        return true;
      }
    }
  },
  data () {
    return {
      content: this.value,
    }
  },
  mounted() {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleInput (value) {
      this.content = value;
    },
    acceptNewValue(){
      this.value = this.content;
      this.$emit('input', this.value);
    },
    discardChanges(){
      this.content = this.value;
    }
  },
  computed: {
    acceptationText(){
      if(this.acceptText){
        return this.acceptText(this.content);
      }
      return `Save <b>${this.content}</b> as new value`;
    },
    showDropdown(){
      return this.content != this.value;
    },
  },
  watch: {
    value(newVal){
      this.content = newVal;
    }
  }
}
</script>

<style scoped>

.holder {
  position: relative;
}

.acceptor {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  z-index: 9999;
  background: white;
  overflow: hidden;
}
.acceptor a {
  display: block;
  background: #FFF;
  color: #35495E;
  padding: .5rem .75rem;
  min-height: 20px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  cursor: pointer;
}
.acceptor a:hover {
  background: #F3F3F3;
}

</style>
