<template>
    <div class="block-activity">
        <div class="row">
            <div class="col-12 col-lg-10 col-xl-8 pr-0">
                <progress-bar
                        :current-step="currentStatus * 1"
                        :steps="statusesList"
                        :showing-count="999"
                        title="Progress"
                        :link="{
                          text: '',
                          href: '',
                        }"
                        v-on="$listeners"
                ></progress-bar>
            </div>
            <div class="col">

            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-10 col-xl-8">

                <block-activity
                  ref="block-activity"
                        :activity="activity"
                        @loadActivity="loadActivity"
                        @loadImages="loadImages"
                        @loadFiles="loadFiles"
                />

            </div>
        </div>
    </div>
</template>

<script>
    import BlockActivity from '@/components/blocks/common/block-activity';
    import ProgressBar from "@/components/ProgressBar";
    import Axios from "axios";

    export default {
        name: 'claim-view-activity-tab',
        components: {
            ProgressBar,
            BlockActivity,
        },
        props: [
            'statusesList',
            'currentStatus',
            'claim',
        ],
        data() {
            return {
                activity: [],
            };
        },
        computed: {},
        methods: {
            loadImages() {
                this.$emit('loadImages');
            },
            loadFiles() {
                this.$emit('loadFiles');
            },
            loadActivity(timestamp = null) {
                let cid = this.$route.params.claimId;
                if (!cid) {
                    return;
                }
                let url = `/ir/activity/get-for-claim/${cid}`;
                if (timestamp > 0) {
                    url += "/" + timestamp;
                }
                Axios.get(url)
                    .then(response => {
                        if (response.data && response.data._status) {
                            this.$store.dispatch('updater/resetActivity');
                            if (timestamp === null) {
                                this.activity = response.data.data;
                            } else {
                                let vm = this;
                                let additional = [];
                                _.forEach(response.data.data, function (na) {
                                    let el = _.find(vm.activity, function (a) {
                                        return Number(a.id) === Number(na.id);
                                    });
                                    if (el) {
                                        console.log('exist element', el);
                                        el = na;
                                    } else {
                                        console.log('additaonal element', na);
                                        additional.push(na);
                                    }
                                });
                                _.forEachRight(additional, function (el) {
                                        vm.activity.unshift(el);
                                    }
                                );
                            }
                        }
                    })
                    .catch(e => console.error(e));
            },
            showNewTaskForm(){
                this.$refs['block-activity'].showNewTaskForm();
            }
        },
        mounted() {
            this.loadActivity();
        },
        watch: {
            claim() {
           // this.loadActivity();
                setTimeout(this.loadActivity, 3000);
            },
            currentStatus() {
                setTimeout(this.loadActivity, 3000);
            }
        }
    };
</script>


<style scoped>
    i.bx-trash {
        font-size: 20px;
        color: red;
        cursor: pointer;
    }

    i.bx-download {
        font-size: 20px;
        color: #00B050;
        cursor: pointer;
        margin-right: 10px;
    }

    i.bx-camera {
        font-size: 42px;
        color: #00B050;
        cursor: pointer;
    }

    a {
        color: #212529;
    }

    a:hover {
        color: #00B050;
        text-decoration: underline;
    }

    @media screen and (min-width: 993px) {
        .pagination-block-paginator {
            right: auto !important;
            left: 1000px !important;
        }
    }

</style>

