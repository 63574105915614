<template>
    <div class="sub-tabs-component">
        <tabs ref="claim-view-other-party-tab-tabs"
              :options="{ defaultTabHash:'op1', useRightContextMenu:true }"
              @handleRightClick="handleRightClickOnTab"
              @changed="propsChangedOtherPartyTab">
            <!--    eslint-disable-->
            <tab :name="'OP '+(index+1)" :id="index" v-for="(op, index) in otherParties" :key="'op'+op.id" :propsActive="(index===0)">
                <claim-view-other-party-tab-other-party
                        v-on="$listeners"
                        :otherParty="op"
                        :claim="claim"
                        :assessment="getAssessmentForOtherParty(op.id)"
                        :rentals="rentals"
                        :current-status="currentStatus"
                        :statuses-list="statusesList"
                        :repairers="repairers"
                        :estimators="estimators"
                        @on-other-party-repairer-updated="onRepairerUpdate"
                        :referrers="referrers"
                        :on-referrer-changed="onReferrerChanged"
                        @saveSignature="saveSignature"
                >

                </claim-view-other-party-tab-other-party>
            </tab>
            <template slot="right-context-item-menu">
                <vue-context
                        ref="tab-context-menu"
                        class="context-menu-right-click list-group"
                        :closeOnClick="true"
                        :closeOnScroll="true"
                        :subMenuOffset="100"
                >
                    <template slot-scope="item">
                        <b-button-group ref="ctx-button-group" vertical class="d-flex">
                            <b-button ref="ctx-button-3" class="text-left font-12 font-nunito--semibold" variant="light" block @click="removeOtherParty(item)">Remove OP</b-button>
                        </b-button-group>
                    </template>
                </vue-context>
            </template>
            <template v-if="countOtherParty<10" slot="nav-item-tab-button">
                <li style="margin-left:30px">
                    <div @click="clickAddAnotherOtherParty" class="clickable">
                        <i class='bx bx-plus' style="color:green;"></i><span>Add Another OP</span>
                    </div>
                </li>
            </template>
        </tabs>
    </div>
</template>
<script>
    /* eslint-disable */

    import Axios from "axios";
    import ClaimViewOtherPartyTabOtherParty from "./other-party/other-party";
    import {VueContext} from 'vue-context';

    export default {
        name: 'claim-view-other-party-tab',
        props: {
            'currentStatus':[Number, String, null],
            'statusesList':[Array, null],
            'otherParties': Array,
            'claim': [Object, null],
            'propsChangedOtherPartyTab': Function,
            'assessments': Array,
            'repairers': Array,
            'estimators': Array,
            rentals: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            'referrers': Array|null,
            'onReferrerChanged': Function,
        },
        data: function () {
            return {};
        },
        computed: {
            countOtherParty() {
                return this.otherParties.length || 0;
            }
        },
        methods: {
            handleRightClickOnTab(e, i) {
                e.preventDefault();
                if (this.countOtherParty > 1) {
                    this.$refs['tab-context-menu'].open(e, i);
                }
            },
            removeOtherParty(v) {
                let index = v.data
                let item = this.otherParties[index];
                NProgress.start();
                let id = this.$route.params.claimId;
                Axios.post('/ir/claim/' + id + '/remove-another-op', {otherPartyId: item.id})
                    .then(response => {
                        if (response.status === 200 && response.data._status) {
                            this.otherParties.splice(index, 1);
                        } else {
                            this.$toast.error(response.data._msg);
                        }
                    })
                    .catch(error => {
                        console.log(error);

                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            clickAddAnotherOtherParty() {
                NProgress.start();
                let id = this.$route.params.claimId;
                Axios.post('/ir/claim/' + id + '/add-another-op')
                    .then(response => {
                        if (response.status === 200 && response.data._status && response.data.otherParty) {
                            this.otherParties.push(response.data.otherParty);
                        } else {
                            this.$toast.error(response.data._msg);
                        }
                    })
                    .catch(error => {
                        console.log(error);

                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            getAssessmentForOtherParty(opId){
              let assessment = null;
              this.assessments.forEach(a => {
                if(a.referenceId == opId){
                  assessment = a;
                }
              })
              return assessment;
            },
            onRepairerUpdate(){
                this.$emit('on-other-party-repairer-updated');
            },
            saveSignature(object) {
                this.$emit('saveSignature', object);
            },
        },
        components: {
            ClaimViewOtherPartyTabOtherParty,
            VueContext,
        }
    };
</script>
<style scoped lang="scss">
    .sub-tabs-component ::v-deep .tabs-component{
        position: inherit;
    }
    </style>
