<script>
    /*eslint no-param-reassign:0*/
    /* eslint-disable */
    import imageGallery from './image-gallery';
    import {isMobile} from 'mobile-device-detect';
    import ImagesFolder from "./images-folder/images-folder";
    import {VueContext} from "vue-context";

    import _ from 'lodash';

    export default {
        name: 'image-lightbox',
        props: [
            'fromComponent',
            'imageList',
            'path',
            'position',
            'isPreviewImage',
            'isDownload',
            'isRemove',
            'isEdit',
        ],
        data: function () {
            return {
                shownGallery: null,
                galleryImages: [],
                lastVerions: {},
                isMobile: false
            };
        },
        computed: {
            categories: function () {
                return Object.keys(this.imageList);
            },
            reversedImageList() {
                return this.imageList.slice().reverse();
            },
            imageClass() {
                switch (this.position) {
                    case "pin_images":
                        return "col-12";
                    case "below_drag_area":
                    default:
                        return "col-4";
                }
            },
            computedImagesForNotCategories() {
                return this.getImagesWithCategory('none');
            },
            computedImagesForExistingDamage() {
                return this.getImagesWithCategory('existing_damage');
            },
            computedImagesForAccidentDamage() {
                return this.getImagesWithCategory('accident_damage');
            },
            computedImagesForSupplementDamage() {
                return this.getImagesWithCategory('supplement_damage');
            },
            computedImagesForAdditionals() {
                return this.getImagesWithCategory('additionals');
            },
            computedImagesForLicense() {
                return this.getImagesWithCategory('license');
            },
        },
        methods: {
            openMoveToMenu(e, item) {
                this.$refs['move-to'].open(e, item);
            },
            moveImg(item, folder) {
                item.imageCategory = folder;
                this.$emit('changeImageCategory', item);
            },
            getImagesWithCategory(category) {
                let images = _.filter(this.imageList, function (img) {
                    if (category === 'none') {
                        return (img.imageCategory === category || img.imageCategory == null);
                    } else {
                        return img.imageCategory === category;
                    }

                });
                return images;
            },
            //imagesByCategory: function (category) {
            //    return this.imageList[category];
            //},
            //makeImageUrl: function (img) {
            //    return img.src.lastIndexOf('data', 0) === 0 ? img.src : this.path + img.src;
            //},
            //makeImagePreviewUrl: function (img) {
            //    return img.url;
            //    const versionPart = this.lastVerions[img.id.toString()] ? '?version=' + this.lastVerions[img.id.toString()] : '';
            //    return (img.previewH240.lastIndexOf('data', 0) === 0 ? img.previewH240 : this.path + img.previewH240) + versionPart;
            //},
            recoverToUpperCase: function (item) {
                return item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ');
            },
            //isDraggedIMG: function (evt) {
            //    if (evt.to.classList.contains('no_images_drag_area') && evt.to.children.length) {
            //        evt.to.classList.remove('no_images_drag_area');
            //    }
            //    if (!evt.from.classList.contains('no_images_drag_area') && evt.from.children.length === 0) {
            //        evt.from.classList.add('no_images_drag_area');
            //    }
            //
            //    let getAttr = evt.item.getAttribute('data-itemArray').split(',');
            //    let itemInfo = this.imageList[getAttr[1]].filter(e => e.id === getAttr[0]);
            //    this.$emit('updateCategoryFunc', {
            //        imgInfo: (itemInfo) ? itemInfo[0] : null,
            //        categoryName: evt.to.getAttribute('data-status-wrap')
            //    });
            //},
            onClickRemove: function (index, img) {
                this.$emit('removeImage', img);
            },
            onClickImage: function (index, images) {
                this.shownGallery = {
                    imageIndex: index,
                    images: images,
                };
            },
            onCloseGallery: function () {
                this.shownGallery = null;
            },
            onImageSaved: function (id) {
                this.lastVerions[id.toString()] = (new Date()).getTime().toString();
            }
        },
        mounted: function () {
            this.isMobile = isMobile;
            //console.log(`_isMobile: ${this.isMobile}`);
        },

        watch: {},
        destroyed: function () {
        },
        components: {
            imageGallery,
            VueContext,
            ImagesFolder,
        }
    };
</script>

<template>
    <div :class="position">
        <div class="image-lightbox">
            <!--            <div class="row no-gutter">-->
            <!--                <template v-for="(img, index) in reversedImageList">-->
            <!--                    <div :class="imageClass" class="img-box-container" :key="img.id">-->
            <!--                        <div v-if="img" class="box img-box">-->
            <!--                            <div v-if="isRemove || isPreviewImage"-->
            <!--                                 class="control-elem-1 justify-content-center align-item-center"-->
            <!--                                 :class="isMobile ? 'd-none' : 'd-lg-flex d-none'">-->
            <!--                                <a v-if="isPreviewImage"-->
            <!--                                   href="#"-->
            <!--                                   class="control-elem-1__item control-elem-1__item&#45;&#45;bordered eye"-->
            <!--                                   @click.prevent="onClickImage(index)"-->
            <!--                                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Enlarge Image', placement:'leftbottom'}">-->
            <!--                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12"-->
            <!--                                         viewBox="0 0 20 12"><title></title>-->
            <!--                                        <g fill="none">-->
            <!--                                            <g fill="#1B1E38">-->
            <!--                                                <path d="M18 6C18 4.2 14.2 2 10 2 5.8 2 2 4.2 2 6 2 7.8 5.8 10 10 10 14.3 10 18 7.8 18 6ZM10 12C5 12 0 9.3 0 6 0 2.7 5 0 10 0 15 0 20 2.7 20 6 20 9.3 15 12 10 12ZM10 10C7.8 10 6 8.2 6 6 6 3.8 7.8 2 10 2 12.2 2 14 3.8 14 6 14 8.2 12.2 10 10 10ZM10 8C11.1 8 12 7.1 12 6 12 4.9 11.1 4 10 4 8.9 4 8 4.9 8 6 8 7.1 8.9 8 10 8Z"/>-->
            <!--                                            </g>-->
            <!--                                        </g>-->
            <!--                                    </svg>-->
            <!--                                </a>-->
            <!--                                <a v-if="isRemove"-->
            <!--                                   href="#"-->
            <!--                                   class="control-elem-1__item"-->
            <!--                                   @click.prevent="onClickRemove(index, img)"-->
            <!--                                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove this image', placement:'leftbottom'}">-->
            <!--                                    <i class="bx bx-trash"></i>-->
            <!--                                </a>-->
            <!--                            </div>-->
            <!--                            <a class="hand-pointer img-box__a" v-if="isPreviewImage"-->
            <!--                               @click="onClickImage(index, null)"-->
            <!--                               @contextmenu.prevent="$refs['move-to'].open($event, img)"-->
            <!--                            >-->
            <!--                                <img :src="makeImagePreviewUrl(img)" class="wrap img-fluid w-100">-->
            <!--                            </a>-->
            <!--                            <a class="hand-pointer img-box__a" v-else @click="onClickImage(index, null)">-->
            <!--                                <img :src="makeImageUrl(img)" class="wrap img-fluid w-100">-->
            <!--                            </a>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--            </div>-->
            <b-list-group>
                <b-list-group-item class="head-of-folders">Images</b-list-group-item>
                <images-folder
                        :categoryName="'Not Categorised'"
                        :category="'none'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForNotCategories"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
                <images-folder
                        :categoryName="'Existing Damage'"
                        :category="'existing_damage'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForExistingDamage"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
                <images-folder
                        :categoryName="'Accident Damage'"
                        :category="'accident_damage'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForAccidentDamage"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
                <images-folder
                        :categoryName="'Supplement Damage'"
                        :category="'supplement_damage'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForSupplementDamage"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
                <images-folder
                        :categoryName="'Additionals'"
                        :category="'additionals'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForAdditionals"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
                <images-folder
                        :categoryName="'License'"
                        :category="'license'"
                        :isPreviewImage="isPreviewImage"
                        :isRemove="isRemove"
                        :position="position"
                        :isMobile="isMobile"
                        :images="computedImagesForLicense"
                        @openMoveToMenu="openMoveToMenu"
                        @onClickImage="onClickImage"
                        @onClickRemove="onClickRemove"
                />
            </b-list-group>
            <!--            <b-list-group>-->
            <!--                <b-list-group-item style="" class="head-of-folders">Images</b-list-group-item>-->
            <!--                <NotCategorised @choseFolder='moveImg' :images="filesForNotCategories"/>-->
            <!--                <ExistingDamage :images="filesForExistingDamage"/>-->
            <!--                <AccidentDamage :images="filesForAccidentDamage"/>-->
            <!--                <SupplementDamage :images="filesForSupplementDamage"/>-->
            <!--                <Additionals :images="filesForAdditionals"/>-->
            <!--                <License :images="filesForLicense"/>-->
            <!--            </b-list-group>-->
        </div>
        <vue-context class="context move-to" ref="move-to">
            <ul slot-scope="child">
                <li class="hasSubContext" @contextmenu.prevent="$refs['to-another-folder'].open">
                    Move To
                    <ul type="none" class="subContext">
                        <li v-if="child && child.data && (child.data.imageCategory !='none' &&  child.data.imageCategory != null )" @click="moveImg(child.data, 'none')">Not Categorised</li>
                        <li v-if="child && child.data && child.data.imageCategory !='existing_damage'" @click="moveImg(child.data, 'existing_damage')">Existing Damage</li>
                        <li v-if="child && child.data && child.data.imageCategory !='accident_damage'" @click="moveImg(child.data, 'accident_damage')">Accident Damage</li>
                        <li v-if="child && child.data && child.data.imageCategory !='supplement_damage'" @click="moveImg(child.data, 'supplement_damage')">Supplement Damage</li>
                        <li v-if="child && child.data && child.data.imageCategory !='additionals'" @click="moveImg(child.data, 'additionals')">Additionals</li>
                        <li v-if="child && child.data && child.data.imageCategory !='license'" @click="moveImg(child.data, 'license')">License</li>
                    </ul>
                </li>
            </ul>

        </vue-context>
        <template v-if="shownGallery">
            <div class="row">
                <div class="col-9">
                    <image-gallery :use-editor="true"
                                   :isDownload="isDownload"
                                   :isRemove="isRemove"
                                   :images="shownGallery.images"
                                   :current-index="shownGallery.imageIndex"
                                   @remove-image="onClickRemove"
                                   @close-gallery="onCloseGallery"
                                   @saved-image="onImageSaved">
                    </image-gallery>
                </div>
                <div class="col-3"></div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>

    .control-elem-1 {
        position: absolute;
        top: 5px;
        right: 5px;
        /*width: 90px;*/
        height: 40px;
        border-radius: 3px;
        background-color: #FFFFFF;
        opacity: 0;
        transition: opacity 150ms ease-in;

        &__item {
            display: block;
            box-sizing: border-box;
            padding: 10px 13px;
        }

        &__item:hover {
            cursor: pointer;
        }

        &__item--bordered {
            border-right: 1px solid rgba(27, 30, 56, 0.1);
        }
    }

    .control-elem-1 span {
        width: 1px;
        border-left: 1px rgba(27, 30, 56, 0.1) solid;
        height: 40px;
        margin-left: 12px;
        display: inline-block;
        cursor: pointer;
    }

    .eye:hover g {
        fill: #00B050;
    }

    .V3 .image-lightbox {
        /*margin-top: 15px;*/
    }

    .pin_images {
        .box {
            margin-right: 0;
        }
    }

    .img-box-container:nth-child(3n+3) .img-box {
        margin-right: 0;
    }

    .hand-pointer {
        cursor: pointer;
    }

    .V3 .below_drag_area {
        width: 100%;
        white-space: nowrap;
        overflow: scroll;
    }

    .V3 .below_drag_area div.box {
        text-align: center;
    }

    .V3 .below_drag_area div.box img {
        width: 100%;
        border-radius: 3px;
    }

    .V3 .below_drag_area .img-box {
        position: relative;
        width: 100%;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a {
        display: inline-block;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .V3 .below_drag_area .img-box,
    .img-box {
        border-radius: 3px;
        overflow: hidden;

        &__a {
            display: block;
            height: 169px;
        }
    }

    .V3 .below_drag_area .img-box:hover > .control-elem-1,
    .img-box:hover > .control-elem-1 {
        opacity: 1;
    }

    .V3 .below_drag_area .each_row_images > div .item_div_drag {
        width: 100%;
    }

    @media screen and (max-width: 992px) and (min-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            /* width: 33.3%; */
        }
    }

    // tablet
    @media screen and (max-width: 768px) {
        .img-box__a {
            height: 130px;
        }
    }

    @media screen and (max-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            width: 100%;
        }
        .img-box-container .img-box {
            // margin-right: 0;
        }
        .img-box__a {
            height: 70px;
        }
    }

    /* extra small device */
    @media screen and (max-width: 350px) {
        .img-box__a {
            height: 18vw;
        }
        .box {
            margin-right: 2.5vw;
            margin-bottom: 2.5vw;
        }
    }

    .V3 .below_drag_area .each_row_images {
        margin: 15px 0;
        padding: 0 15px;
        border-right: 1px solid lightgray;
        width: 283px;
        display: inline-block;
        text-align: center;
    }

    .V3 .each_row_images:last-child {
        border-right: none;
    }

    .V3 .below_drag_area .categories_title {
        height: 3rem;
        text-align: start;
        padding-top: 10px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
    }

    .V3 .below_drag_area .area-empty-images {
        opacity: 0.25;
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin: auto;
    }

    .V3 .below_drag_area .hidden-area-empty-images {
        display: none;
    }

    .V3 .below_drag_area .no_images_drag_area {
        height: 30rem;
        margin: 1rem 0;
        text-align: center;
        padding-top: 82px;
    }

    .V3 .below_drag_area .draggable-images {
        width: 100%;
        height: 30rem;
        overflow: scroll;
        margin: 1rem 0;
    }

    .V3 .below_drag_area .text-title-categories {
        margin: 1rem;
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
    }

    .V3 .below_drag_area .icon-action-block {
        position: absolute;
        height: 40px;
        width: 90px;
        border-radius: 3px;
        background-color: #FFFFFF;
        top: 5px;
        right: 5px;
    }

    .V3 .below_drag_area .icon-action-block div {
        cursor: pointer;
    }

    .V3 .below_drag_area .icon-action-block i {
        font-size: 20px;
        font-weight: bold;
    }

    .V3 .below_drag_area .action-button {
        float: left;
        width: 50%;
        height: 100%;
        padding-top: 9px;
    }

    .V3 .below_drag_area .action-button:first-child {
        border-right: 1px solid rgba(27, 30, 56, 0.1);;
    }

    .V3 .pin_images {
        .image-lightbox {
            width: 100%;
            margin-right: 0;
            margin-left: 0;

            > .row .col-12 {
                padding: 0;
            }
        }

        .img-box {
            margin-bottom: 15px;

            &__a {
                height: 160px;
                background-color: #FFFFFF;
            }

            &__a > img {
                height: 160px;
                border-radius: 5px;
                object-fit: cover;
            }
        }
    }
</style>

<style scoped>
    .context {
        border-radius: .25rem;
    }

    .subContext {
        border-radius: .25rem;
    }

    .context.v-context ul li:hover {
        background-color: #E9FFF3 !important;
        color: #00B050 !important;
        cursor: pointer;
    }

    .context.v-context ul {
        padding: 0px;
    }

    .context.v-context ul > li {
        padding: 10px 13px;
        font-size: 12px;
        line-height: 16px;
    }

    .subContext {
        position: absolute;
        left: calc(100% + 1px);
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
        min-width: 150px;
        width: auto;
        top: 0;
        transition: 0.4s;
        visibility: hidden;
        opacity: 0;
        right: auto;
        transform: translateY(-10px);
        color: #1C1F39;
    }


    .context li {
        list-style: none;
        font-weight: 600;
        line-height: 16px;
        padding: 13px;
        font-size: 12px;
    }

    .context {
        width: auto;
    }

    .move-to:hover .subContext {
        opacity: 1;
        visibility: visible;
        transform: translateY(-1px);
    }

    .hasSubContext:after {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        bottom: 0;
        margin: auto;
        left: calc(100% - 15px);
        width: 0;
        height: 0;
        border-color: transparent transparent transparent #5D6471;
        border-style: solid;
        border-width: 5px;
    }

    .hasSubContext {
        padding-right: 25px !important;
    }

    .to-another-folder {

    }
</style>
