<template>
    <tasks-dashboard v-if="isTasksDashboard" />
    <accident-management-dashboard v-else-if="isAccidentManagement" />
    <default-dashboard v-else-if="isInsurance" />
    <assessments-dashboard v-else-if="isAssessments" />
    <default-dashboard v-else />
</template>

<script>

    import AccidentManagementDashboard from "./accident-management/dashboard";
    import AssessmentsDashboard from "./assessments/dashboard";
    import DefaultDashboard from "./default/dashboard";
    import TasksDashboard from "./tasks/dashboard";
    import {mapGetters} from 'vuex';

    export default {
    name: "Dashboard",
    components: {
        AccidentManagementDashboard,
        DefaultDashboard,
        AssessmentsDashboard,
        TasksDashboard,
    },
        computed: {
            ...mapGetters({
                isAssessments: "currentCompany/isAssessments",
                isInsurance: "currentCompany/isInsurance",
                isAccidentManagement: "currentCompany/isAccidentManagement",
                isUserRoleAssessor: "currentUser/isRoleAssessor",
                isUserRoleManager: "currentUser/isRoleManager",
                isUserRoleLawyer: "currentUser/isRoleLawyer",
            }),
            isTasksDashboard(){
                return this.isUserRoleAssessor || this.isUserRoleManager || this.isUserRoleLawyer;
            }
        },
};
</script>
