<template>
    <div class="dashboard-page">
        <div class="page-header">
            <h4>Dashboard</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </div>
        <div class="widget-panel white-bg mb-0 request-for-quotes">
<!--            <RequestForQuotes :width="'803.5px'" />-->
        </div>
        <div class="widget-container" >
            <div class="widget-panel parts-order-panel">
<!--                <PartsOrders />-->
            </div>
            <div class="widget-panel notifications-panel">
<!--                <Notifications />-->
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "DashboardForDefault",
    components: {
    }
};
</script>