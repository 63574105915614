<template>
    <b-list-group-item class="item">
        <div @click='chooseFolder' class="line-of-folder">
            <div>
                <i :class="classForIcon" class="icon-folder"></i>
                <span class="name-of-folder">{{categoryName}}</span>
                <span class="countOfImages">{{this.images.length}}</span>
            </div>
            <div class="text-right">
                <i class='bx bx-dots-horizontal-rounded dotter-button'></i>
            </div>
        </div>
        <div v-if="showImg && reversedImages.length>0" class="row no-gutter show-image">
            <template v-for="(img, index) in reversedImages">
                <div :class="imageClass" class="img-box-container" :key="img.id">
                    <div v-if="img" class="box img-box">
                        <div v-if="isRemove || isPreviewImage"
                             class="control-elem-1 justify-content-center align-item-center"
                             :class="isMobile ? 'd-none' : 'd-lg-flex d-none'">
                            <a v-if="isPreviewImage"
                               href="#"
                               class="control-elem-1__item control-elem-1__item--bordered eye"
                               @click.prevent="onClickImage(index)"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Enlarge Image', placement:'leftbottom'}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12"
                                     viewBox="0 0 20 12"><title></title>
                                    <g fill="none">
                                        <g fill="#1B1E38">
                                            <path d="M18 6C18 4.2 14.2 2 10 2 5.8 2 2 4.2 2 6 2 7.8 5.8 10 10 10 14.3 10 18 7.8 18 6ZM10 12C5 12 0 9.3 0 6 0 2.7 5 0 10 0 15 0 20 2.7 20 6 20 9.3 15 12 10 12ZM10 10C7.8 10 6 8.2 6 6 6 3.8 7.8 2 10 2 12.2 2 14 3.8 14 6 14 8.2 12.2 10 10 10ZM10 8C11.1 8 12 7.1 12 6 12 4.9 11.1 4 10 4 8.9 4 8 4.9 8 6 8 7.1 8.9 8 10 8Z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                            <a v-if="isRemove"
                               href="#"
                               class="control-elem-1__item"
                               @click.prevent="onClickRemove(img)"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Remove this image', placement:'leftbottom'}">
                                <i class="bx bx-trash"></i>
                            </a>
                        </div>
                        <a class="hand-pointer img-box__a" v-if="isPreviewImage"
                           @click="onClickImage(index)"
                           @contextmenu.prevent="openMoveToMenu($event,img)"
                        >
                            <img :src="makeImagePreviewUrl(img)" class="wrap img-fluid w-100">
                        </a>
                        <a class="hand-pointer img-box__a" v-else @click="onClickImage(index)">
                            <img :src="makeImageUrl(img)" class="wrap img-fluid w-100">
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </b-list-group-item>
</template>

<script>
    /* eslint-disable */
    export default {
        name: "ImagesFolder",
        components: {},
        props: {
            images: {
                type: Array,
                default: () => [],
            },
            position: String,
            isRemove: Boolean,
            isPreviewImage: Boolean,
            isMobile: Boolean,
            categoryName: {
                type: String,
                default: () => '',
            },
            category: {
                type: String,
                default: () => '',
            },

        },
        data() {
            return {
                showImg: false,
            };
        },
        methods: {
            onClickRemove: function (img) {
                this.$emit('onClickRemove', null, img);
            },
            onClickImage(index){
               this.$emit('onClickImage',index, this.reversedImages)
            },
            openMoveToMenu(e, item) {
                this.$emit('openMoveToMenu', e, item);
            },
            makeImagePreviewUrl: function (img) {
                return img.url;
            },
            makeImageUrl: function (img) {
                return img.src.lastIndexOf('data', 0) === 0 ? img.src : this.path + img.src;
            },
            chooseFolder() {
                this.showImg = !this.showImg;
            },
        },
        computed: {
            classForIcon() {
                if (this.showImg) {
                    return 'bx bx-folder-open';
                } else {
                    return 'bx bx-folder';
                }
            },
            imageClass() {
                switch (this.position) {
                    case "pin_images":
                        return "col-12";
                    case "below_drag_area":
                    default:
                        return "col-4";
                }
            },
            reversedImages() {
                return this.images.slice().reverse();
            }
        }
    };
</script>

<style>
    .countOfImages{
      margin-left: 10px;
      color: #5E79FF;
      background-color: rgba(95, 122, 255, 0.1);
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 0.3em 0.7em 0.3em;
    }
    .name-of-folder {
        margin-bottom: 10% !important;
        font-size: 12px;
        font-weight: bold;
        top: -1px;
        margin-left: 10px;
        position: relative;
    }

    .icon-folder {
        font-size: 24px;
        color: #00B050;
        transform: translateY(6px);
    }

    .dotter-button {
        color: #34495E;
        font-size: 24px;
        font-weight: bolder;
        margin-top: 5px;
    }

    i.bx-trash {
        font-size: 20px;
        color: red;
        float: right;
        cursor: pointer;
    }

    .head-of-folders {
        text-align: left;
        background-color: #F4F2EE !important;
        font-weight: bold
    }

    .button-in-folders {
        background: transparent;
        border: none !important;
        float: right;
    }

    .imagesInFolder {
        height: 300px;
        width: 380px;
        margin: 10px;
        border-radius: 10px;
    }

    .item {
        text-align: left;
    }

    .line-of-folder {
        display: grid;
        grid-template-columns: 9fr  1fr;

    }

    .show-image {
        margin-top: 10px;
    }

    .line-of-folder:hover {
        cursor: pointer;
    }

    .close-img {
        float: right;
    }
</style>

<style lang="scss" scoped>

    .control-elem-1 {
        position: absolute;
        top: 5px;
        right: 5px;
        /*width: 90px;*/
        height: 40px;
        border-radius: 3px;
        background-color: #FFFFFF;
        opacity: 0;
        transition: opacity 150ms ease-in;

        &__item {
            display: block;
            box-sizing: border-box;
            padding: 10px 13px;
        }

        &__item:hover {
            cursor: pointer;
        }

        &__item--bordered {
            border-right: 1px solid rgba(27, 30, 56, 0.1);
        }
    }

    .control-elem-1 span {
        width: 1px;
        border-left: 1px rgba(27, 30, 56, 0.1) solid;
        height: 40px;
        margin-left: 12px;
        display: inline-block;
        cursor: pointer;
    }

    .eye:hover g {
        fill: #00B050;
    }

    .pin_images {
        .box {
            margin-right: 0;
        }
    }

    .img-box-container:nth-child(3n+3) .img-box {
        margin-right: 0;
    }

    .hand-pointer {
        cursor: pointer;
    }

    .V3 .below_drag_area {
        width: 100%;
        white-space: nowrap;
        overflow: scroll;
    }

    .V3 .below_drag_area div.box {
        text-align: center;
    }

    .V3 .below_drag_area div.box img {
        width: 100%;
        border-radius: 3px;
    }

    .V3 .below_drag_area .img-box {
        position: relative;
        width: 100%;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a {
        display: inline-block;
        height: 250px;
    }

    .V3 .below_drag_area .img-box a img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .V3 .below_drag_area .img-box,
    .img-box {
        border-radius: 3px;
        overflow: hidden;

        &__a {
            display: block;
            height: 169px;
        }
    }

    .V3 .below_drag_area .img-box:hover > .control-elem-1,
    .img-box:hover > .control-elem-1 {
        opacity: 1;
    }

    .V3 .below_drag_area .each_row_images > div .item_div_drag {
        width: 100%;
    }

    @media screen and (max-width: 992px) and (min-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            /* width: 33.3%; */
        }
    }

    // tablet
    @media screen and (max-width: 768px) {
        .img-box__a {
            height: 130px;
        }
    }

    @media screen and (max-width: 576px) {
        .V3 .below_drag_area .each_row_images > div .item_div_drag {
            width: 100%;
        }
        .img-box-container .img-box {
            // margin-right: 0;
        }
        .img-box__a {
            height: 70px;
        }
    }

    /* extra small device */
    @media screen and (max-width: 350px) {
        .img-box__a {
            height: 18vw;
        }
        .box {
            margin-right: 2.5vw;
            margin-bottom: 2.5vw;
        }
    }

    .V3 .below_drag_area .each_row_images {
        margin: 15px 0;
        padding: 0 15px;
        border-right: 1px solid lightgray;
        width: 283px;
        display: inline-block;
        text-align: center;
    }

    .V3 .each_row_images:last-child {
        border-right: none;
    }

    .V3 .below_drag_area .categories_title {
        height: 3rem;
        text-align: start;
        padding-top: 10px;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.1);
    }

    .V3 .below_drag_area .area-empty-images {
        opacity: 0.25;
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        margin: auto;
    }

    .V3 .below_drag_area .hidden-area-empty-images {
        display: none;
    }

    .V3 .below_drag_area .no_images_drag_area {
        height: 30rem;
        margin: 1rem 0;
        text-align: center;
        padding-top: 82px;
    }

    .V3 .below_drag_area .draggable-images {
        width: 100%;
        height: 30rem;
        overflow: scroll;
        margin: 1rem 0;
    }

    .V3 .below_drag_area .text-title-categories {
        margin: 1rem;
        font-family: "Nunito Sans";
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
    }

    .V3 .below_drag_area .icon-action-block {
        position: absolute;
        height: 40px;
        width: 90px;
        border-radius: 3px;
        background-color: #FFFFFF;
        top: 5px;
        right: 5px;
    }

    .V3 .below_drag_area .icon-action-block div {
        cursor: pointer;
    }

    .V3 .below_drag_area .icon-action-block i {
        font-size: 20px;
        font-weight: bold;
    }

    .V3 .below_drag_area .action-button {
        float: left;
        width: 50%;
        height: 100%;
        padding-top: 9px;
    }

    .V3 .below_drag_area .action-button:first-child {
        border-right: 1px solid rgba(27, 30, 56, 0.1);;
    }

    .V3 .pin_images {
        .image-lightbox {
            width: 100%;
            margin-right: 0;
            margin-left: 0;

            > .row .col-12 {
                padding: 0;
            }
        }

        .img-box {
            margin-bottom: 15px;

            &__a {
                height: 160px;
                background-color: #FFFFFF;
            }

            &__a > img {
                height: 160px;
                border-radius: 5px;
                object-fit: cover;
            }
        }
    }
</style>
