<script>
    /* eslint-disable */
    import NumberFormatter from "./utility/number-formatter";
    import Multiselect from "vue-multiselect";
    import moment from 'moment';
    import RentalTolls from './block-rental-tolls'
    export default {
        name: 'block-rental-allocation',
        props: {
            allocation: {
                type: Object,
                default: function () {
                    return {
                        creditCardVerification: null,
                        hirerSentNotice: '',
                        dateHirerNoticeSent: '',
                        rentalRef: '',
                        customerDeliveryLocation: '',
                        deliveryDriver: '',
                        deliverySentNotice: '',
                        dateDeliveryNoticeSent: '',
                        pickUpLocation: '',
                        startHireOdometer: '',
                        dropOfLocation: '',
                        endHireOdometer: '',
                        licenseVerification: 'Not verified',
                    };
                }
            },
            tolls: {
              type: [Array, null],
              default: () => [],
            },
            isAllDisable: {
                type: Boolean,
                default: function () {
                    return false;
                },
            }
        },
        data: function () {
            return {
              licenseVerificationStatuses: [
                {
                  key: 'none',
                  label: 'Not verified',
                },
                {
                  key: 'failed',
                  label: 'Verification attempt failed',
                },
                {
                  key: 'successful',
                  label: 'Verified',
                },
              ],
            };
        },
        methods: {
          rentalAllocationTabChanged(a){
            console.log(a);
          },
          addNewToll(){
            this.$emit('add-new-toll');
          },
          deleteToll(toll) {
            this.$emit('delete-toll', toll);
          }
        },

        computed:{
            computedPaymentDate(){
                let date =  moment(this.allocation.creditCardVerification.paymentDate).format('DD/MM/YYYY');
                return 'Verified - ' + date;
            },
          licenseVerificationC: {
            get(){
              let status = {};
              this.licenseVerificationStatuses.forEach(s => {
                if(s.key == this.allocation.licenseVerification){
                  status = s;
                }
              })
              return status;
            },
            set(newVal){
              this.allocation.licenseVerification = newVal.key;
            }
          }
        },
        watch: {},
        components: {
          NumberFormatter,
          Multiselect,
          RentalTolls,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Allocation</strong>
        </div>
        <div class="card-block bg-white">

          <tabs ref="claim-view-rental-tab-tabs" id="rental-tab-allocation-tabs" @changed="rentalAllocationTabChanged">

            <tab name="Hirer Verification" id="rental-tab-allocation-hirer-verification">
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Credit Card sent notice</label>
                <div class="col-sm-8 col-8">
                  <label class="form-check-inline radio">
                    <input v-model="allocation.creditCardSentNotice" class="form-check-input" type="radio" :value="true"
                           @click="(!!allocation.creditCardSentNotice)?allocation.creditCardSentNotice=null:null"
                           @change="allocation.creditCardSentNotice=true"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Yes</span>
                  </label>
                  <label class="form-check-inline radio">
                    <input v-model="allocation.creditCardSentNotice" class="form-check-input" type="radio" :value="false"
                           @click="(!allocation.creditCardSentNotice)?allocation.creditCardSentNotice=null:null"
                           @change="allocation.creditCardSentNotice=false"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">No</span>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date notice sent</label>
                <div class="col-sm-8 col-8">
                  <b-form-datepicker
                      v-model="allocation.dateCreditCardSentNotice"
                      class="mb-2 "
                      locale="en-GB"
                      :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                      :disabled="isAllDisable"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Credit Card Verification</label>
                <div class="col-sm-8 col-8">
                  <template v-if="allocation.creditCardVerification">
                    <input  class="form-control" type="text" :value="computedPaymentDate" readonly :disabled="isAllDisable">
                  </template>
                  <template v-else>
                    <input  class="form-control" type="text" value="Not verified" readonly :disabled="isAllDisable">
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">License Request sent notice</label>
                <div class="col-sm-8 col-8">
                  <label class="form-check-inline radio">
                    <input v-model="allocation.licenseRequestSentNotice" class="form-check-input" type="radio" :value="true"
                           @click="(!!allocation.licenseRequestSentNotice)?allocation.licenseRequestSentNotice=null:null"
                           @change="allocation.licenseRequestSentNotice=true"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Yes</span>
                  </label>
                  <label class="form-check-inline radio">
                    <input v-model="allocation.licenseRequestSentNotice" class="form-check-input" type="radio" :value="false"
                           @click="(!allocation.licenseRequestSentNotice)?allocation.licenseRequestSentNotice=null:null"
                           @change="allocation.licenseRequestSentNotice=false"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">No</span>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date notice sent</label>
                <div class="col-sm-8 col-8">
                  <b-form-datepicker
                      v-model="allocation.dateLicenseRequestSentNotice"
                      class="mb-2 "
                      locale="en-GB"
                      :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                      :disabled="isAllDisable"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">License Verification</label>
                <div class="col-sm-8 col-8">

                  <multiselect
                      v-model="licenseVerificationC"
                      :options="licenseVerificationStatuses"
                      track-by="key"
                      label="label"
                      :showLabels="false"
                      :option-height="29"
                      :max-height="203"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="License Verification"
                      :disabled="isAllDisable"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Hirer sent notice</label>
                <div class="col-sm-8 col-8">
                  <label class="form-check-inline radio">
                    <input v-model="allocation.hirerSentNotice" class="form-check-input" type="radio" value="true"
                           @click="(!!allocation.hirerSentNotice)?allocation.hirerSentNotice=null:null"
                           @change="allocation.hirerSentNotice=true"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Yes</span>

                  </label>
                  <label class="form-check-inline radio">
                    <input v-model="allocation.hirerSentNotice" class="form-check-input" type="radio" value="false"
                           @click="(!allocation.hirerSentNotice)?allocation.hirerSentNotice=null:null"
                           @change="allocation.hirerSentNotice=false"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">No</span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date notice sent</label>
                <div class="col-sm-8 col-8">
                  <b-form-datepicker
                      v-model="allocation.dateHirerNoticeSent"
                      class="mb-2 "
                      locale="en-GB"
                      :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                      :disabled="isAllDisable"
                  ></b-form-datepicker>
                </div>
              </div>
            </tab>

            <tab name="Delivery" id="rental-tab-allocation-hirer-delivery">

              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Customer Delivery Location</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="allocation.customerDeliveryLocation" class="form-control" :disabled="isAllDisable">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Delivery Driver</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="allocation.deliveryDriver" class="form-control" :disabled="isAllDisable">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Delivery sent notice</label>
                <div class="col-sm-8 col-8">
                  <label class="form-check-inline radio">
                    <input v-model="allocation.deliverySentNotice" class="form-check-input" type="radio" value="true"
                           @click="(!!allocation.deliverySentNotice)?allocation.deliverySentNotice=null:null"
                           @change="allocation.deliverySentNotice=true"
                           :disabled="isAllDisable"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">Yes</span>

                  </label>
                  <label class="form-check-inline radio">
                    <input v-model="allocation.deliverySentNotice" class="form-check-input" type="radio" value="false"
                           @click="(!allocation.deliverySentNotice)?allocation.deliverySentNotice=null:null"
                           @change="allocation.deliverySentNotice=false"
                           :disabled="isAllDisable">
                    <span class="icon"><i class='bx bx-check'></i></span>
                    <span class="text">No</span>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Date notice sent</label>
                <div class="col-sm-8 col-8">
                  <b-form-datepicker
                      v-model="allocation.dateDeliveryNoticeSent"
                      class="mb-2 "
                      locale="en-GB"
                      :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                      :disabled="isAllDisable"
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Pick Up Location</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="allocation.pickUpLocation" class="form-control" :disabled="isAllDisable">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Drop of Location</label>
                <div class="col-sm-8 col-8">
                  <input type="text" v-model="allocation.dropOfLocation" class="form-control" :disabled="isAllDisable">
                </div>
              </div>
            </tab>

            <tab name="Tolls" id="rental-tab-allocation-hirer-tolls">
              <rental-tolls :tolls="tolls" @add-new-toll="addNewToll" @delete-toll="deleteToll"></rental-tolls>
            </tab>
          </tabs>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
