<script>
  /* eslint-disable */
  import NumberFormatter from '@/components/number-formatter';

  export default {
        name: 'block-claim-type',
        props: {
            rentalTotal:{
                type:[Number, null],
                default: function(){
                    return 0;
                }
            },
          claim: {
            type: Object,
              default: function () {
                  return {
                      type_diminished_value: false,
                      type_rectification: false,
                      type_rental: false,
                      type_repairers: false,
                      totalAmountSettled: 0,
                      settledOn: null,
                  };
            },
          },
          otherParty: {
            type: Object,
            default: function () {
              return {
                id: 0,
                type_diminished_value: false,
                type_rectification: false,
                type_rental: false,
                type_repairers: false,
              };
            },
          },
          hideHeader: {
              type: Boolean,
              default: function () {
                  return false;
              }
          },
          rentals: {
              type: Array,
              default: function () {
                  return [];
              }
          },
          assessmentSubtotal: {
              type: [String, Number],
              default: function () {
                  return 0;
              },
          },
        },
        data: function () {
            return {
              readyToListenChanges: false,
            };
        },
        watch: {
        },
      mounted() {
        setTimeout(() => {
          this.readyToListenChanges = true;
        },300)
      },
      methods: {
          statusChanged(){
            if(!this.readyToListenChanges) return;
            this.$emit('on-claim-types-changed', {
              type_diminished_value: this.otherParty.id ? this.otherParty.type_diminished_value : this.claim.type_diminished_value,
              type_rectification: this.otherParty.id ? this.otherParty.type_rectification : this.claim.type_rectification,
              type_rental: this.otherParty.id ? this.otherParty.type_rental : this.claim.type_rental,
              type_repairers: this.otherParty.id ? this.otherParty.type_repairers : this.claim.type_repairers,
              totalAmountSettled: this.claim.totalAmountSettled,
              settledOn: this.claim.settledOn,
              otherPartyId: this.otherParty.id,
            })
          }
        },

        computed: {
          newCheckAllowed(){
            let c = 0;
            if(this.otherParty.id){
              if(this.otherParty.type_diminished_value) {
                c++;
              }
              if(this.otherParty.type_rectification) {
                c++;
              }
              if(this.otherParty.type_rental) {
                c++;
              }
              if(this.otherParty.type_repairers) {
                c++;
              }
            } else {
              if(this.claim.type_diminished_value) {
                c++;
              }
              if(this.claim.type_rectification) {
                c++;
              }
              if(this.claim.type_rental) {
                c++;
              }
              if(this.claim.type_repairers) {
                c++;
              }
            }
            return c < 2;
          },
          totalRepair(){
            return parseFloat((this.assessmentSubtotal * 1.1).toFixed(2));
          },
        },

        components: {
          NumberFormatter,
        }

    };
</script>
<template>
    <div class="card">
        <div v-if="!hideHeader" class="card-header bg-navyblue">
            <strong>Claim Type</strong>
        </div>
        <div class="card-block bg-white">

            <template v-if="otherParty.id">
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="other_party_type_repairers" class="col-sm-5 col-5 col-form-label">Repairs</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="otherParty.type_repairers"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_repairers"
                           id="other_party_type_repairers" value="1" :disabled="!newCheckAllowed && !otherParty.type_repairers">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="other_party_type_rental" class="col-sm-5 col-5 col-form-label">Rental</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="otherParty.type_rental"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_rental"
                           id="other_party_type_rental" value="1" :disabled="!newCheckAllowed && !otherParty.type_rental">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="other_party_type_rectification" class="col-sm-5 col-5 col-form-label">Rectification</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="otherParty.type_rectification"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_rectification"
                           id="other_party_type_rectification" value="1" :disabled="!newCheckAllowed && !otherParty.type_rectification">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label for="other_party_type_diminished_value" class="col-sm-5 col-5 col-form-label">Diminished Value</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="otherParty.type_diminished_value"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_diminished_value"
                           id="other_party_type_diminished_value" value="1" :disabled="!newCheckAllowed && !otherParty.type_diminished_value">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="claim_type_repairers" class="col-sm-5 col-5 col-form-label">Repairs</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="claim.type_repairers"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_repairers"
                           id="claim_type_repairers" value="1" :disabled="!newCheckAllowed && !claim.type_repairers">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="claim_type_rental" class="col-sm-5 col-5 col-form-label">Rental</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="claim.type_rental"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_rental"
                           id="claim_type_rental" value="1" :disabled="!newCheckAllowed && !claim.type_rental">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row" style="margin-bottom: 5px !important;">
                <label for="claim_type_rectification" class="col-sm-5 col-5 col-form-label">Rectification</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="claim.type_rectification"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_rectification"
                           id="claim_type_rectification" value="1" :disabled="!newCheckAllowed && !claim.type_rectification">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
              <div class="form-group row">
                <label for="claim_type_diminished_value" class="col-sm-5 col-5 col-form-label">Diminished Value</label>
                <div class="col-sm-7 col-7">
                  <label class="form-check-inline checkbox">
                    <input v-model="claim.type_diminished_value"
                           @change="statusChanged()"
                           class="form-check-input" type="checkbox" name="type_diminished_value"
                           id="claim_type_diminished_value" value="1" :disabled="!newCheckAllowed && !claim.type_diminished_value">
                    <span class="icon"><i class='bx bx-check'></i></span>
                  </label>
                </div>
              </div>
            </template>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
