<script>
  /* eslint-disable */

  import BlockAddress from "./block-address";
  import AdvancedSearch from "@/components/utility/advanced-search";
  import Axios from "axios";
  import _ from "lodash";

  export default {
        name: 'block-claims-repairer-new',
        props: {
          innerRepairer: {
            type: Object,
            default: function () {
              return {
                id: 0,
                businessName: null,
                approvalStatus: null,
                licenseNumber: '',
                email: '',
                phone: '',
                address: {},
                newName: '',
              };
            }
          },
          isReadonly:{
              type: Boolean,
              default: false,
          },
          changeNotAllowed: {
            type: Boolean,
            default: false,
          },
          estimator: {
            type: Object|Boolean,
            default: function () {
              return {
                id: 0,
                name: '',
              };
            }
          },
          estimators: {
            type: Array,
            default: function () {
              return [];
            }
          },
          onEstimatorChanged: Function,
          showApprovedStatus: false,
          showEstimators: true,
        },
        data: function () {
            return {
              selectedRepairerName: '',
              repairers: [],
              estimatorName: '',
            };
        },
        methods: {
          loadRepairers() {
            NProgress.start();
            Axios.get('/ir/repairers/short')
                    .then(response => {
                      if (response && response.data && response.data._status) {
                        this.repairers = response.data.data;
                      }
                    })
                    .catch(e => console.error(e))
                    .finally(() => {
                      NProgress.done();
                    });
          },
          addRepairer(name) {
            Axios.post('/ir/repairers', {name: name})
                    .then(response => {
                      if (response && response.data && response.data._status) {
                        this.loadRepairers();
                        this.innerRepairer.id = response.data.id;
                        this.innerRepairer.businessName = name;
                      } else {
                      }
                    })
                    .catch(e => console.error(e));

          },
          onRepairerSelect(r) {
            console.info(r)
            if (r) {
              this.innerRepairer.id = r.id;
              this.innerRepairer.businessName = r.businessName;
              this.selectedRepairerName = r.businessName;
              this.innerRepairer.licenseNumber = r.licenseNumber;
              this.innerRepairer.email = r.email;
              this.innerRepairer.phone = r.phone;
              this.innerRepairer.newName =null;
              if (r.address) {
                this.innerRepairer.address.country = r.address.country;
                this.innerRepairer.address.levelUnitLot = r.address.levelUnitLot;
                this.innerRepairer.address.location = r.address.location;
                this.innerRepairer.address.postcode = r.address.postcode;
                this.innerRepairer.address.state = r.address.state;
                this.innerRepairer.address.streetName = r.address.streetName;
                this.innerRepairer.address.suburb = r.address.suburb;
              }
              this.$emit('on-repairer-select', this.innerRepairer);
            } else {
              this.innerRepairer.id = 0;
              this.innerRepairer.businessName = '';
              this.innerRepairer.approvalStatus = null;
              this.innerRepairer.licenseNumber = '';
              this.innerRepairer.email = '';
              this.innerRepairer.phone = '';
              this.innerRepairer.address.country = 'AU';
              this.innerRepairer.address.levelUnitLot = '';
              this.innerRepairer.address.location = '';
              this.innerRepairer.address.postcode = '';
              this.innerRepairer.address.streetName = '';
              this.innerRepairer.address.suburb = '';
              this.innerRepairer.address.state = '';
              this.addRepairer(this.selectedRepairerName);

            }
          },
          changeField() {
            let r = _.find(this.repairers, item => {
              return Number(item.id) === Number(this.innerRepairer.id);
            });
            if (r) {
              r.businessName = this.innerRepairer.businessName;
              r.licenseNumber = this.innerRepairer.licenseNumber;
              r.email = this.innerRepairer.email;
              r.phone = this.innerRepairer.phone;
              r.address.country = this.innerRepairer.address.country;
              r.address.levelUnitLot = this.innerRepairer.address.levelUnitLot;
              r.address.location = this.innerRepairer.address.location;
              r.address.postcode = this.innerRepairer.address.postcode;
              r.address.state = this.innerRepairer.address.state;
              r.address.suburb = this.innerRepairer.address.suburb;
              r.address.streetName = this.innerRepairer.address.streetName;
            }
          },
          changeRepairerName(id) {
            let r = _.find(this.repairers, item => {
              return Number(item.id) === Number(id);
            });
            if (r && r.businessName) {
              this.selectedRepairerName = r.businessName;
            }
          },
          changeEstimatorName(id) {
            console.log('estimator.id', this.estimator, id);
            let est = _.find(this.estimators, item => {
              return Number(item.id) === Number(id);
            });
            if (est && est.name) {
              this.estimatorName = est.name;
            }
          },
          onEstimatorSelect(val) {
            if (!this.estimator){
              this.estimator ={
                id: 0,
                name: '',
              };
            }
            if (val) {
              this.estimator.id = val.id;
            } else {
              this.estimator.id = 0;
              this.estimator.name = this.estimatorName;
            }
          },
        },
        computed: {
          computedEstimator: {
            get() {
             // this.changeEstimatorName(this.estimator.id);
              return this.estimatorName;
            },
            set(val) {
              this.estimatorName = val;
            },
          },
          computedRepairerName: {
            get(){
              return this.selectedRepairerName;
            },
            set(newVal){
              this.selectedRepairerName = newVal;
            //  console.log('sss', newVal);
            }
          },
        },
        watch: {
          'innerRepairer.id': function (newVal) {
            this.changeRepairerName(newVal);
          },
          'innerRepairer': {
            deep: true,
            handler(newVal) {
              console.log('changeField');
              this.changeField();
            }
          },
          'repairers': function () {
            this.changeRepairerName(this.innerRepairer.id);
          },
          'estimators': function () {
            this.changeEstimatorName(this.estimator.id);
          },
          'estimator.id': function (newVal) {
            this.changeEstimatorName(newVal);
          }
        },
    mounted() {
      this.loadRepairers();
      if(this.innerRepairer && this.innerRepairer.businessName){
        this.selectedRepairerName = this.innerRepairer.businessName;
      }
      this.$nextTick(() => {
        this.changeEstimatorName(this.estimator.id);
      });
    },
        components: {
          BlockAddress,
          AdvancedSearch,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Repairer</strong>
        </div>

      <div class="card-block bg-white">
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Repairer Name</label>
          <div class="col-sm-8 col-8">
            <advanced-search ref="repairer.name"
                             searchLabel="Repairer Name"
                             v-model="computedRepairerName"
                             :searchData="repairers"
                             searchKey="businessName"
                             @onSelect="onRepairerSelect"
                             :placeholderText="'Repairer Name'"
                             :is-disabled="isReadonly"
            >
            </advanced-search>
            <!--            <select-->
            <!--                @input="onRepairerSelect"-->
            <!--                class="form-control"-->
            <!--                v-if="!changeNotAllowed"-->
            <!--            >-->
            <!--              <option v-for="rep in repairers" :key="'repairer-' + rep.id" :value="rep.id" :selected="rep.id == innerRepairer.id">{{ rep.businessName }}</option>-->
            <!--            </select>-->

          </div>
        </div>
        <div class="form-group row" v-if="showEstimators">
          <label class="col-sm-4 col-4 col-form-label">Estimator</label>
          <div class="col-sm-8 col-8">
            <advanced-search ref="estimator"
                             searchLabel="Estimator"
                             v-model="computedEstimator"
                             :searchData="estimators"
                             searchKey="name"
                             @onSelect="onEstimatorSelect"
                             :placeholderText="'Estimator'"
                             :is-disabled="isReadonly"
            >
            </advanced-search>
          </div>
        </div>
        <div class="form-group row" v-if="showApprovedStatus">
          <label class="col-sm-4 col-4 col-form-label">Repairer Approval status</label>
          <div class="col-sm-8 col-8">
            <label class="form-check-inline radio">
              <input v-model="innerRepairer.approvalStatus" class="form-check-input" type="radio" value="true"
                     @click="(!!innerRepairer.approvalStatus) ? innerRepairer.approvalStatus = null : null"
                     @change="innerRepairer.approvalStatus = true" :disabled="isReadonly">
              <span class="icon"><i class='bx bx-check'></i></span>
              <span class="text">Pre Aprvd</span>
            </label>
            <label class="form-check-inline radio" style="width:130px;">
              <input v-model="innerRepairer.approvalStatus" class="form-check-input" type="radio" value="false"
                     @click="(!innerRepairer.approvalStatus) ? innerRepairer.approvalStatus = null : null"
                     @change="innerRepairer.approvalStatus = false" :disabled="isReadonly">
              <span class="icon"><i class='bx bx-check'></i></span>
              <span class="text">Custom Choice</span>
            </label>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">License Nbr</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="innerRepairer.licenseNumber" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Email</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="innerRepairer.email" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Phone Nbr</label>
          <div class="col-sm-8 col-8">
            <input type="text" v-model="innerRepairer.phone" class="form-control" :readonly="isReadonly">
          </div>
        </div>
        <block-address :address="innerRepairer.address" :isReadonly="isReadonly"></block-address>
      </div>

    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
