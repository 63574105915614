<script>
    /*eslint-disable */
    export default {
        name: 'advanced-search',
        props: {
            value: null,
            searchData: {
                type: Array,
                default: () => []
            },
            searchKey: {
                type: String,
                default: ''
            },
            searchLabel: {
                type: String,
                default: ''
            },
            openOnFocus: {
                type: Boolean,
                default: false
            },
            openOnInput: {
                type: Boolean,
                default: true
            },
            showOnFocus: {
                type: Boolean,
                default: false
            },
            hideOnBlur: {
                type: Boolean,
                default: true
            },
            hideOnEscape: {
                type: Boolean,
                default: true
            },
            maxRecords: {
                type: Number,
                default: 10
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ''
            },
            id: {
                type: String
            },
        },
        data: function () {
            return {
                currentPosition: 0,
                show: false,
                isNew: false,
                mouseInSearchResults: false,
                backupValue: false,
            };
        },
        mounted() {
            setTimeout(() => {
                this.backupValue = this.value;
            }, 200);
        },
        computed: {
            asbValue: {
                get: function () {
                    this.isNew = false;
                    return this.value ? this.value : '';
                },
                set: function (newValue) {
                    this.$emit('input', newValue);
                }
            },
            asbValueLowerCase: function () {
                return this.asbValue.toLowerCase();
            },
            searchd: function () {
                try {
                    if (this.searchData) {
                        return this.searchData;
                    }
                    return [];
                } catch (e) {
                    return [];
                }
            },
            key: function () {
                //   console.log(this.searchKey)
                return this.searchKey;
            },
            regTerm: function () {
                //   console.log(new RegExp(this.asbValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi"))
                return new RegExp(this.asbValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi");
            },
            asbValueExists: function () {
                if (this.key) {
                    //    console.log(this.searchResult.findIndex(item => item[this.key].toLowerCase() === this.asbValueLowerCase))
                    return this.searchResult.findIndex(item => item[this.key].toLowerCase() === this.asbValueLowerCase);
                }
//console.log(this.searchResult.findIndex(item => item.toLowerCase() === this.asbValueLowerCase))
                return this.searchResult.findIndex(item => item.toLowerCase() === this.asbValueLowerCase);
            },
            searchResult: function () {
                let rTerm = new RegExp(this.asbValue, 'i');
                let finalResult = new Array();
                let take = this.maxRecords;
                if (this.searchKey) {
                    for (let i = 0; i < this.searchd.length; i++) {
                        let d = this.searchd[i];
                        if (!_.isEmpty(d) && d[this.key].match(rTerm)) {
                            finalResult.push(d);
                            take = take - 1;
                            if (take === 0) {
                                break;
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < this.searchd.length; i++) {
                        let d = this.searchd[i];
                        if (!_.isEmpty(d) && d.match(rTerm)) {
                            finalResult.push(d);
                            take = take - 1;
                            if (take === 0) {
                                break;
                            }
                        }
                    }
                }

                return finalResult;
            },
            searchResultCount: function () {
                return this.searchResult.length;
            }
        },
        methods: {
            clickForShow() {
                if (this.isDisabled) {
                    this.show = false;
                    return;
                }
                this.show = !this.show;
            },
            onChange: function () {

                this.backupValue = this.asbValue;
                //   console.log('change');
                // console.log(d);
                //this.$emit('onChange', d);
            },
            onSelect: function (d) {
                if (!this.searchLabel && !d) {
                    return;
                }
                this.show = false;
                this.isNew = !d;
                this.$emit('onSelect', d);
                this.$emit('onChange', d);
            },
            onEscape: function () {
                // console.log('esc pressed', this.hideOnEscape)
                if (this.hideOnEscape) {
                    this.show = false;
                }
            },
            onFocus: function () {
                if (this.showOnFocus) {
                    this.show = true;
                }
            },
            onBlur: function () {
                //   console.log('blur');
                //  console.log(d);
                if (this.hideOnBlur && !this.mouseInSearchResults) {
                    this.show = false;
                    // this.keyEnter()
                }
            },
            keyDownPrevent: function () {
                //  console.log('down.prevent');
                // console.log(d);
                if (this.currentPosition < this.searchResultCount) {
                    this.currentPosition = this.currentPosition + 1;
                } else {
                    this.currentPosition = 0;
                }
                this.show = true;
            },
            keyDown: function (e) {
                if (e.code === "Escape") {
                    this.show = false;
                    this.asbValue = this.backupValue;
                    return;
                }
                this.show = true;
                //if (d.keyCode == 9 || d.key == 'Tab') {
                //    this.keyEnter();
                //}
            },
            keyUp: function () {
                //   console.log('up');
                //   console.log(d);
                if (this.currentPosition <= 0) {
                    this.currentPosition = this.searchResultCount;
                } else {
                    this.currentPosition = this.currentPosition - 1;
                }
                this.show = true;
            },
            keyEnter: function () {
                //   console.log('enter');
                //   console.log(d);
                // console.log(this.currentPosition, this.searchResultCount, this.searchResult[this.currentPosition])
                if (this.currentPosition >= this.searchResultCount) {
                    this.onSelect(null);
                } else {
                    this.onSelect(this.searchResult[this.currentPosition]);
                }
            },
            mouseOver: function (index) {
                this.currentPosition = index;
            },
            mouseEnter: function () {
                // console.log('mousEnter')
                this.mouseInSearchResults = true;
            },
            mouseLeave: function () {
                // console.log('mousLeave')
                //   console.log('leave');
                //   console.log(d);
                this.mouseInSearchResults = false;
            },
            keyPress: function () {
                //    console.log(e);
            }
        }
    };
</script>

<template>
    <div>
        <span @click="clickForShow" class="text-grey" :class="{'display-none': isNew}" style="position: absolute; top: 18px; transform:translateY(-50%); right: 1.5rem;">
            <i class="bx" :class="{'bxs-chevron-down': !show, 'bxs-chevron-up': show}"></i>
        </span>
        <span :class="{'display-none': !isNew, 'text-grey': true}" style="position:absolute;top: 50%; transform:translateY(-50%);right:2rem;">NEW</span>
        <input ref="asb" type="text" v-model="asbValue" class="form-control"
               @keypress="keyPress"
               @keydown="keyDown"
               @keydown.down.prevent="keyDownPrevent"
               @keydown.up.prevent="keyUp"
               @keyup.enter.prevent="keyEnter"
               @keyup.esc.prevent="onEscape"
               @blur="onBlur"
               @focus="onFocus"
               :disabled="isDisabled"
               :placeholder="placeholder"
               :id="id"
               @change="onChange">
        <div v-if="show" :class="{'search-box-container': true}">
            <div class="search-box">
                <div class="search-results">
                    <ul v-if="searchKey" class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult" v-bind:key="index"
                            @mouseover="mouseOver(index)"
                            @click="keyEnter"
                            v-html="sr[key].replace(regTerm, '<b>$&</b>')"></li>
                        <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                    <ul v-else class="sr-options" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
                        <li :class="{'selected': index == currentPosition, 'sr-option': true}" v-for="(sr, index) in searchResult"
                            v-bind:key="index"
                            @mouseover="mouseOver(index)"
                            @click="keyEnter"
                            v-html="sr.replace(regTerm, '<b>$&</b>')"></li>
                        <li :class="{'selected': searchResultCount == currentPosition, 'sr-option': true}" v-if="searchLabel && asbValue && asbValueExists === -1"
                            @mouseover="mouseOver(searchResultCount)" @click="keyEnter">+ New {{searchLabel}}<br/><strong>{{asbValue}}</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .search-box-container {
        position: relative;
    }

    .search-box-container .search-box {
        position: absolute;
        width: 100%;
        z-index: 99;
        border: 1px solid rgba(0, 0, 0, .15);
        margin-top: -1px;
        background-color: #FFFFFF;
        max-height: 17rem;
        overflow-y: auto;
    }

    .search-box-container .search-box ul.sr-options {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }

    .search-box-container .search-box ul.sr-options li.sr-option {
        cursor: pointer;
        padding: 6px;
        user-select: none;
        -webkit-user-select: none;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
    }

    /*.search-box-container .search-box ul.sr-options li.sr-option:hover, */
    .search-box-container .search-box ul.sr-options li.sr-option.selected {
        background-color: #F5F5F5 !important;
        color: #5E6571;
    }

</style>

<style scoped>
    .V3 span.text-grey i {
        font-size: 24px;
        color: #1C1F39;
    }
</style>
