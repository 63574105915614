<script>
    /* eslint-disable */
    import BlockAddress from "./block-address";
    import _ from "lodash";
    import AdvancedSearchWithNew from "@/components/utility/advancedSearchWithNew";
    import Axios from "axios";
    import {mapGetters} from "vuex";

    export default {
        name: 'block-other-party-details',
        props: {
            hideProps: {
                type: Array,
                default: function () {
                    return []
                }
            },
            isAssessmentsPage: {
              type: Boolean,
              default: false,
            },
            details: {
                type: Object,
                default: function () {
                    return {
                        vehicleOwner: '',
                        ownerType: 'I',
                        contact: '',
                        email: '',
                        address: {},
                    };
                }
            },
          vehicleOwnerNames: {
            type: Array,
            default() {
              return [];
            }
          },
            isReadonly:{
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {
              localName: '',
            };
        },
        mounted() {
        },
        watch: {
          'details': {
            immediate: true,
            handler(newVal) {
              console.log('newVal', newVal)
              this.localName = newVal.vehicleOwner;
            }
          },
        },
        methods: {
          onVehicleOwnerSelect(name) {
            console.log('onVehicleOwnerSelect', name)
            if (name === null) {
              this.details.vehicleOwner = this.computedVehicleOwnerName;
              this.details.name = this.computedVehicleOwnerName;
              return;
            }
            this.details.vehicleOwner = name;
            this.details.name = name;
            },
          },
        computed: {
            ...mapGetters({
              isAssessmentsCompany: "currentCompany/isAssessments",
            }),
            computedVehicleOwnerName: {
              get() {
                if (this.isAssessmentsCompany && this.isAssessmentsPage) {
                  return this.localName;
                }

                return this.details.vehicleOwner;
              },
              set(data) {
                console.log('try to set insured name', data);
                if (this.isAssessmentsCompany && this.isAssessmentsPage) {
                  this.localName = data;
                  return;
                }
                this.details.vehicleOwner = data;
                this.details.name = data;
              },
            },
            isHideLicenseNumber() {
                if (this.hideProps && _.indexOf(this.hideProps, 'licenseNumber')>=0 ) {
                    return true;
                }
                return false;
            },
            isHideLicenseCountry() {
                if (this.hideProps && _.indexOf(this.hideProps, 'licenseCountry')>=0 ) {
                    return true;
                }
                return false;
            },
            isHideLicenseState() {
                if (this.hideProps && _.indexOf(this.hideProps, 'licenseState')>=0 ) {
                    return true;
                }
                return false;
            }
        },
        components: {
            AdvancedSearchWithNew,
            BlockAddress,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Details</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label for="owner" class="col-sm-4 col-4 col-form-label">Vehicle Owner</label>
                <div class="col-sm-8 col-8">
                    <advanced-search-with-new
                        v-if="isAssessmentsCompany && isAssessmentsPage"
                                              ref="compInsured.name"
                                              :isShowAddUpdate="true"
                                              searchLabel="Vehicle Owner"
                                              v-model="computedVehicleOwnerName"
                                              :searchData="vehicleOwnerNames"
                                              @onSelect="onVehicleOwnerSelect"
                                              @onUpdate="onVehicleOwnerSelect"
                                              :placeholderText="'Vehicle Owner'"
                    >
                    </advanced-search-with-new>
                    <input v-else type="text" v-model="details.vehicleOwner" class="form-control" :readonly="isReadonly">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Owner Type</label>
                <div class="col-sm-8 col-8">
                    <label class="form-check-inline radio">
                        <input v-model="details.ownerType"  :disabled="isReadonly"
                               class="form-check-input" type="radio"
                               value="I" :checked="details.ownerType ==='I'">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Individual</span>

                    </label>
                    <label class="form-check-inline radio">
                        <input v-model="details.ownerType"  :disabled="isReadonly"
                               class="form-check-input" type="radio"
                               value="B" :checked="details.ownerType ==='B'">
                        <span class="icon"><i class='bx bx-check'></i></span>
                        <span class="text">Business</span>
                    </label>
                </div>
            </div>

            <div class="form-group row">
              <label for="contact" class="col-sm-4 col-4 col-form-label">Driver Name</label>
              <div class="col-sm-8 col-8">
                <input type="text" v-model="details.driverName" class="form-control" id="driver-name" placeholder="Driver Name" :disabled="isReadonly">
              </div>
            </div>

            <div class="form-group row">
                <label for="contact" class="col-sm-4 col-4 col-form-label">Contact Nbr</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-model="details.contact" class="form-control"  :readonly="isReadonly">
                </div>
            </div>


            <div class="form-group row">
                <label for="email" class="col-sm-4 col-4 col-form-label">Email</label>
                <div class="col-sm-8 col-8">
                    <input type="email" v-model="details.email" class="form-control"  :readonly="isReadonly">
                </div>
            </div>

            <block-address :address="details.address" :isReadonly="isReadonly">

            </block-address>

            <div v-if="!isHideLicenseNumber" class="form-group row" style="margin-top: 15px;">
            <label for="license-number" class="col-sm-4 col-4 col-form-label">License Number</label>
            <div class="col-sm-8 col-8">
              <input type="text" v-model="details.licenseNumber" class="form-control" id="license-number" placeholder="License Number"  :readonly="isReadonly">
            </div>
          </div>

            <div v-if="!isHideLicenseCountry" class="form-group row">
            <label for="license-country" class="col-sm-4 col-4 col-form-label">License Country</label>
            <div class="col-sm-8 col-8">
              <input type="text" v-model="details.licenseCountry" class="form-control" id="license-country" placeholder="License Country"  :readonly="isReadonly">
            </div>
          </div>

            <div v-if="!isHideLicenseState && details.licenseState" class="form-group row">
            <label for="license-state" class="col-sm-4 col-4 col-form-label">License State</label>
            <div class="col-sm-8 col-8">
              <input type="text" v-model="details.licenseState" class="form-control" id="license-state" placeholder="License State"  :readonly="isReadonly">
            </div>
          </div>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
