<template>
    <div ref="image-pin-area" class="image-pin-area" @keydown="handleKeydown" tabindex="0">
        <div>
            <div class="d-lg-none">
                <label class="pin-image">
                    Pin Images
                    <input type="checkbox" name="pinimage" @change="close" checked/>
                    <span class="pin-checkmark"
                    ><i class="fa fa-check"></i
                    ></span>
                </label>
            </div>
            <div class="img-grid">
                <div v-for="(img, index) in imageList" :key="index" class="img-grid__wrapper">
                    <a ref="img" tabindex="0" class="img-grid__link" @click="handleClickImage(index, null)">
                        <img class="img-grid__img" :src="img.url">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    components: {

    },
    props: [
        'image-list'
    ],
    data() {
        return {
            showGallery: false,
            index: 0,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        handleClickImage(index) {
            this.$emit('click-image', index);
        },
        handleKeydown(e) {
            e.preventDefault();
            switch(e.keyCode) {
                case 37: // left arrow
                case 38: // up arrow
                    {
                        this.index --;
                        if (this.index <= -1) {
                            this.index = this.$refs['img'].length - 1;
                        }
                        this.$refs['img'][this.index].focus();
                        break;
                    }
                case 39: // right arrow
                case 40: // bottom arrow
                    {
                        this.index ++;
                        if (this.index >= this.$refs['img'].length) {
                            this.index = 0;
                        }
                        this.$refs['img'][this.index].focus();
                        break;
                    }
                case 32: // spacebar
                case 13: // enter
                    {
                        this.handleClickImage(this.index);
                        break;
                    }
                case 27: // esc
                default:
                    {
                        this.close();
                    }
            }
        }
    },
    mounted() {
        this.index = 0;
        this.$refs['image-pin-area'].focus();
    }
};
</script>

<style scoped>
.image-pin-area__icon {
    font-size: 56px;
    color: white;
}

.img-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.img-grid__img {
    width: 100%;
    border-radius: 3px;
}

.img-grid__link {
    display: inline-block;

    cursor: pointer;
    /* transition: opacity ease-out 250ms; */
    border: 2px solid #08dc68;
}

.img-grid__link:focus,
.img-grid__link:hover {
    border-radius: 3px;
    box-sizing: border-box;
    /* opacity: 0.8; */
    border: 2px solid #bdffdb;
}
</style>