<template>
    <div>
        <div class="row">
            <div class="col-md-8">
                <block-accident-details :details="accident.details">

                </block-accident-details>
            </div>
            <div class="col-md-4">
                <block-accident-towing :towing="accident.towing">

                </block-accident-towing>
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */

    import BlockAccidentDetails from "../../../components/block-accident-details";
    import BlockAccidentTowing from "../../../components/block-accident-towing";

    export default {
        name: 'claim-view-accident-tab',
        props: [
            'accident'
        ],
        data: function () {
            return {};
        },
        components: {
            BlockAccidentTowing,
            BlockAccidentDetails
        }
    };
</script>