<script>
    /* eslint-disable */

    import NumberFormatter from "@/components/utility/number-formatter";

    export default {
        name: 'block-assessment-information',
        props: {
            assessment: {
                validator: prop => typeof prop === 'object' || prop === null,
                default: function () {
                    return {
                        number: '',
                        assessorName: '',
                        id: 0,
                        assessmentValue: 0,
                    };
                }
            },
            withRecoveriesOn: {
                type: String,
                default: null,
            },
            isShow: {
                type: Boolean,
                default: false,
            },
            claim: {
              type: Object,
              default(){
                return {
                  id: 0,
                  number: '',
                  totalAmountSettled: 0,
                  manualQuoteTotal: null,
                  manualAssessedTotal: null,
                  manualInvoiceValue: null,
                }
              }
            },
            settled: {
              type: Object,
              default: function(){
                return {
                  repairSettledAmount: null,
                  repairSettledDate: null,
                  repairLegalFees: null,
                  rentalTotalAmount: null,
                  rentalSettledAmount: null,
                  rentalSettledDate: null,
                  rentalLegalFees: null,
                  injuryTotalAmount: null,
                  injurySettledAmount: null,
                  injurySettledDate: null,
                  injuryLegalFees: null,
                  assessmentFeeTotalAmount: null,
                  assessmentFeeSettledAmount: null,
                  assessmentFeeLegalFees: null,
                };
              },
            },
        },
        computed: {
            assessmentId() {
                return (this.isShow && this.assessment && this.assessment.id) ? this.assessment.id : 0;
            },
            number() {
                return (this.isShow && this.assessment && this.assessment.number) ? this.assessment.number : '';
            },
            assessorName() {
                return (this.isShow && this.assessment && this.assessment.assessorName) ? this.assessment.assessorName : '';
            }
        },
      methods: {
        withRecoveriesOnChanged(){
          this.$emit('with-recoveries-on-changed', this.withRecoveriesOn)
        }
      },
      components:{
        NumberFormatter,
      },
    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Assessment Information</strong>
        </div>
        <div class="card-block bg-white">
          <div class="form-group row">
              <label class="col-sm-5 col-5 col-form-label">Assessment Nbr</label>
              <div class="col-sm-7 col-7">
                  <div class="form-control is-link" v-if="assessmentId > 0">
                      <router-link v-for="(link, num) in assessment.estimatesLinks" :key="num" :to="link" style="color: #00b050">{{ num }} </router-link>
                  </div>
                  <input v-else type="text" :value="number" class="form-control" readonly>
              </div>
          </div>
          <div class="form-group row">
            <label for="owner" class="col-sm-5 col-5 col-form-label">Assessment Total</label>
            <div class="col-sm-7 col-7">
              <number-formatter v-if="settled.assessmentSubtotal !== null" v-model="settled.assessmentSubtotal" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
              <number-formatter v-else v-model="claim.manualAssessedTotal" class="form-control numeric" format="$0,0.00"></number-formatter>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 col-5 col-form-label">Assessor Name</label>
            <div class="col-sm-7 col-7">
              <input type="text" :value="assessorName" class="form-control" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 col-5 col-form-label">Date with Recoveries</label>
            <div class="col-sm-7 col-7">
              <b-form-datepicker
                  v-model="withRecoveriesOn"
                  class="mb-2 "
                  locale="en-AU"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                  @input="withRecoveriesOnChanged"
              ></b-form-datepicker>
            </div>
          </div>
          <div class="form-group row">
            <label for="owner" class="col-sm-5 col-5 col-form-label">Assessment Invoice</label>
            <div class="col-sm-7 col-7">
              <number-formatter v-if="assessment" v-model="assessment.assessmentValue" class="form-control numeric" format="$0,0.00"></number-formatter>
              <number-formatter v-else value="0" class="form-control numeric" format="$0,0.00" readonly=""></number-formatter>

            </div>
          </div>

        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
