<template>
    <div>
        <div :class="{'block-uploader':true, 'files-upload': true, 'dynamic-width': dynamicWidth}" v-if="!readOnly">
            <vue-dropzone v-on:vdropzone-file-added="addFileUpload"
                          v-on:vdropzone-success="successFileUpload"
                          v-on:vdropzone-complete="completeFileUpload"
                          v-on:vdropzone-sending="sendFileUpload"
                          ref="fileUpDrop"
                          id="fileUpDrop"
                          class="dropify-wrapper dropzoneContainer"
                          style="height: auto;"
                          :options="getConfig"
                          :useCustomSlot="true">
                <div class="dropify-message">
                    <i class='bx' :class="{
                        'bx-camera': isMobile, 
                        'bx-cloud-upload': !isMobile, 
                        ['clickBrowseFilesBtn_' + type]: true}"></i>
                    
                    <p class="drop-down-text">
                        <template v-if="isMobile">Take a Photo</template>
                        <template v-else>
                            Drag and Drop
                            <template v-if="type==='image'">Images</template>
                            <template v-else>Files</template>
                            Here
                        </template>
                    </p>
                    <p class="text-or">Or</p>
                    <div class="text-sm-center ex-image-upload-buttons">
                        <button type="button" :id="'clickBrowseFilesBtn_' + type"
                                :class="'clickBrowseFilesBtn_' + type"
                                class="btn btn-primary waves-effect waves-light browse-btn">Browse&nbsp;<template v-if="type==='image'">Images</template><template v-else>Files</template>
                        </button>
                        <button @click="clickCancelDropzoneFiles" type="button"
                                class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel
                        </button>
                    </div>
                </div>
            </vue-dropzone>
        </div>
        <template v-if="!uploaderOnly">
          <div v-if="type==='image'" class="files-upload block" :class="{'mt-0': readOnly}">
              <div class="block-header bg-navyblue">
                  Images
              </div>
              <image-lightbox :isRemove="!readOnly"
                              :isEdit="!readOnly"
                              :isDownload="true"
                              :isPreviewImage="true"
                              :position="''"
                              :imageList="files"
                              @removeImage="deleteImage"
                              :path="''"
              ></image-lightbox>
          </div>
        </template>
    </div>
</template>

<script>
/* eslint-disable */
import vueDropzone from 'vue2-dropzone';
import Axios from "axios";
import _ from "lodash";
import ImageLightbox from "@/components/image-lightbox/image-lightbox";
import {isMobile} from 'mobile-device-detect';

export default {
    name: "FileUpload",
    components: {
        vueDropzone,
        ImageLightbox,
    },
    data() {
        return {
            fileIds: [],
            deleteFileIds: [],
            files: this.value,
        }
    },
    props: {
        config: {
            type: Object,
            default(){
                return {
                    maxFilesize: 100,
                    thumbnailWidth: 200,
                    timeout: 300000,
                    addRemoveLinks: true,
                    resizeWidth: 1500,
                    resizeQuality: 0.7,
                    acceptedFiles: 'image/jpeg, image/jpg, image/png',
                }
            }
        },
        objectId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            default: 'image'
        },
        value: {
            type: Array,
            required: true
        },
        apiUrls: {
            type: Object,
            default: function () {
                return {
                    uploadFile: '',
                    main: '',
                    removeFile: ''
                }
            }
        },
        filesAccepted: {
            type: String,
            default: 'application/pdf, .doc, .docx'
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        uploaderOnly: {
            type: Boolean,
            default: true,
        },
        dynamicWidth: {
            type: Boolean,
            default: false,
        },
        afterUploadFunctionOverride: {
            type: Function,
            default: null,
        },
    },
    mounted() {
        this.files = this.value;
    },
    methods: {
        autoSaveFile: function () {
            let data = {};

            //ToDo test to comment this place
            //this.$emit('afterUpload');

            if(this.afterUploadFunctionOverride != null){
                console.log('!!!!!!!!!!!!!')
              return this.afterUploadFunctionOverride();
            }

            if (this.type === 'image') data.imageIds = this.fileIds;
            else
                data.fileIds = this.fileIds;

            Axios.post(this.apiUrls.main + this.objectId, data)
                .then(response => {
                    if (response.data && response.data.rfq) {
                        this.files = (this.type === 'image') ? response.data.rfq.images : response.data.rfq.files;
                        this.fileIds = [];
                    }
                    this.$toast.success("File has been uploaded");
                })
                .catch(error => {
                    this.$toast.error("File hasn't been uploaded");
                    console.log(error);
                });
        },
        autoRemoveFile: function () {
            let data = {},
                remove = this.apiUrls.main + this.objectId;

            if (this.type === 'image') data.deleteImageIds = this.deleteFileIds;
            else
                data.deleteFileIds = this.deleteFileIds;

            if (!_.isEmpty(this.apiUrls.removeFile)) remove = this.apiUrls.removeFile;

            Axios.post(remove, data)
                .then(() => {
                    this.deleteFileIds = [];
                    this.$toast.success("File has been deleted");
                })
                .catch(error => {
                    this.$toast.error("File hasn't been deleted");
                    console.log(error);
                });
        },
        autoFileImage: function () {
            let update = {};
            update.fileIds = this.fileIds;
        },
        addFileUpload($event) {
            $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
        },
        completeFileUpload($event) {
            $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute("style");
        },
        successFileUpload(file, response) {
            if (response.status || response._status) {
                if(this.afterUploadFunctionOverride !== null){
                    this.afterUploadFunctionOverride(file, response);
                }
                this.fileIds.push(response.fileId);
                this.autoSaveFile();
                let vm = this;
                setTimeout(function () {
                    vm.$refs.fileUpDrop.removeFile(file);
                }, 1000);
            }
        },
        sendFileUpload($event) {
            $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
        },

        clickCancelDropzoneFiles: function () {
            this.$refs.fileUpDrop.removeAllFiles(true);
            this.fileIds = [];
        },
        deleteImage: function (index, file) {
            this.deleteFile(file);
        },
        deleteFile: function (file) {
            if (file && file.id && file.id > 0) {
                this.deleteFileIds.push(file.id);
                this.autoRemoveFile();
                this.files = _.reject(this.files, function (item) {
                    return Number(item.id) === Number(file.id);
                })
            }
        },
    },
    computed: {
        fileUploadDropzoneOptions(){
          return {
            clickable: '.clickBrowseFilesBtn_' + this.type,
            url: appConfig.baseAPIURL + this.apiUrls.uploadFile,
            headers: {"Authorization": localStorage.getItem('token')},
            method: 'post',
            timeout: 300000,
            addRemoveLinks: true,
          }
        },
        getImagesConfig() {
          let config = this.fileUploadDropzoneOptions;
          config.acceptedFiles = 'image/jpeg, image/jpg, image/png';
          config.thumbnailWidth = 200;
          config.resizeWidth = 1500;
          config.resizeQuality = 0.7;
          config.maxFilesize = 150;
          if (this.config && this.config.maxFiles && this.config.maxFiles>0){
              config.maxFiles = this.config.maxFiles;
          }else {
              config.maxFiles = 150;
          }
          config.dictFileTooBig = "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again";
          return config;
        },
        getFilesConfig() {
          let config = this.fileUploadDropzoneOptions;
          config.acceptedFiles = this.filesAccepted;
          config.maxFilesize = 10;
            if (this.config && this.config.maxFiles && this.config.maxFiles>0){
                config.maxFiles = this.config.maxFiles;
            }else {
                config.maxFiles = 15;
            }
          config.dictFileTooBig = "Your file has exceeded the maximum limit of 2MB per file. Please reduce the file size and upload again";
          return config;
        },
        getConfig: function () {
          let c = (this.type === 'image') ? this.getImagesConfig : this.getFilesConfig;
          setTimeout(() => {
            if(this.$refs.fileUpDrop){
              this.$refs.fileUpDrop.dropzone.options.url = c.url;
              console.log('UPDROP',this.$refs.fileUpDrop);
            }
          }, 200)
          return c;
        },
        isMobile() {
            return isMobile;
        }
    },
    watch: {
        value: {
            handler: function (val, old) {
                if (val.length !== old.length) {
                    this.files = val;
                }
            },
            deep: true
        },
        files: {
            handler: function (val) {
                if (!_.isEmpty(val)) this.$emit('input', val);
            },
            deep: true
        }
    }
}
</script>

<style>

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}
.dz-preview {
  display: none !important;
}
/* overwrite dropzone */
.dropzone {
    padding: 0px;
}

</style>

<style scoped>
i.bx-trash {
    font-size: 20px;
    color: red;
    cursor: pointer;
}

i.bx-download {
    font-size: 20px;
    color: #00b050;
    cursor: pointer;
    margin-right: 10px;
}

i.bx-camera {
    font-size: 42px;
    color: #00b050;
    cursor: pointer;
}

a {
    color: #212529;
}

a:hover {
    color: #00b050;
    text-decoration: underline;
}

.block-uploader .files-upload {
    /* width: 864px; */
    /* margin-left: 15px; */
    /* margin-top: 15px; */
}

.block-uploader .files-upload {
    width: 100%;
    /* padding: 15px 15px 0 15px; */
    margin-left: unset;
    margin-right: unset;
    margin-top: unset;

}

@media screen and (min-width: 993px) {
    .block-uploader .files-upload {
        /* width: 864px; */
        padding: unset;
        /* margin-left: 15px; */
        /* margin-top: 15px; */
    }

    .pagination-block-paginator {
        right: auto !important;
        left: 1000px !important;
    }
}

</style>
