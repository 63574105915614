<script>
    /* eslint-disable */

    import NumberFormatter from "@/components/utility/number-formatter";
    import {mapGetters} from 'vuex';

    export default {
        name: 'block-other-party-repairer-quote',
        props: {
            repairerQuote: {
                type: Object,
                default: function () {
                    return {
                        quoteNumber: '',
                        quoteStatus: '',
                    };
                }
            },
            assessmentTabInfo: {
                type: Object,
                default(){
                    return {
                        quoteNumber:'',
                        quoteDate: null,
                    }
                }
            },
            isReadonly:{
                type: Boolean,
                default: false,
            },
          isDollarsMethodology:{
                type: Boolean,
                default: false,
            },
          allItems: [Array, null],
            claim: {
                type: Object,
                default(){
                    return {
                      id: 0,
                      number: '',
                      totalAmountSettled: 0,
                      manualQuoteTotal: null,
                      manualAssessedTotal: null,
                      manualInvoiceValue: null,
                    }
                }
            },
            settled: {
              type: Object,
              default: function(){
                return {
                  repairSettledAmount: null,
                  repairSettledDate: null,
                  repairLegalFees: null,
                  rentalTotalAmount: null,
                  rentalSettledAmount: null,
                  rentalSettledDate: null,
                  rentalLegalFees: null,
                  injuryTotalAmount: null,
                  injurySettledAmount: null,
                  injurySettledDate: null,
                  injuryLegalFees: null,
                  assessmentFeeTotalAmount: null,
                  assessmentFeeSettledAmount: null,
                  assessmentFeeLegalFees: null,
                };
              },
            },
        },
        data: function () {
            return {};
        },
        methods: {},

        computed: {
            ...mapGetters({
                isUserRoleAssessor: 'currentUser/isRoleAssessor',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            quoteTotal() {
              if (!this.isDollarsMethodology) {
                return this.settled.assessmentSubtotal
              }
              let subtotal = this.quotedSubtotal + this.quotedGST;
              return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedSubtotal() {
              let subtotal = 0;
              _.forEach(this.allItems, function (estimate) {
                if (estimate && estimate.info) {
                  subtotal += estimate.info.quotedSubtotal;
                }
              });
              subtotal = parseFloat(parseFloat(subtotal).toFixed(2));
              return subtotal;
            },
            quotedGST() {
              let subtotal = this.quotedSubtotal * 0.1;
              return parseFloat(parseFloat(subtotal).toFixed(2));
            },
        },
        watch: {},
        components: {
          NumberFormatter,
        }

    };
</script>
<template>
    <div class="card">
        <div class="card-header bg-navyblue">
            <strong>Repairer Quote</strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Quote Nbr</label>
                <div class="col-sm-8 col-8">
                    <input type="text" v-if="repairerQuote && repairerQuote.quoteNumber" v-model="repairerQuote.quoteNumber" class="form-control"  :readonly="isReadonly">
                    <input type="text" v-else-if="assessmentTabInfo" v-model="assessmentTabInfo.quoteNumber" class="form-control"  :readonly="isReadonly">
                </div>
            </div>
            <div class="form-group row">
              <label for="owner" class="col-sm-4 col-4 col-form-label">Quote Total</label>
              <div class="col-sm-8 col-8">
                <input v-if="quoteTotal !== null" :value="quoteTotal | formatMoney" class="form-control numeric" readonly></input>
                <number-formatter v-else value="0" class="form-control numeric" format="$0,0.00" readonly></number-formatter>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label">Quote Date</label>
                <div class="col-sm-8 col-8">
                    <b-form-datepicker
                            v-model="assessmentTabInfo.quoteDate"
                            today-button
                            reset-button
                            close-button
                            class="mb-2"
                            locale="en-GB"
                            :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                            :disabled="isUserRoleLawyer"
                    ></b-form-datepicker>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
