<script>
    /* eslint-disable */

    export default {
        name: 'block-reasons-for-rental',
        props: {
            hideHeader: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            reasons: {
                type: Object,
                default: function () {
                    return {
                        requiresBecause: '',
                        onlyOptionBecause: '',
                        publicTransportSuitability: '',
                        similarReplacement: '',
                        licenseNbr: '',
                        licenseRegisterdIn: '',
                    };
                }
            },
        },
        data: function () {
            return {};
        },
    };

</script>
<template>
    <div class="card">
        <div  v-if="!hideHeader" class="card-header bg-navyblue">
            <strong>Reasons for Rental </strong>
        </div>
        <div class="card-block bg-white">
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Requires Because </label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.requiresBecause" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Only option Because</label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.onlyOptionBecause" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Public transport suitability</label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.publicTransportSuitability" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">Similar replacement</label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.similarReplacement" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">License Nbr </label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.licenseNbr" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 col-5 col-form-label">License Registered in </label>
                <div class="col-sm-7 col-7">
                    <input type="text" v-model="reasons.licenseRegisterdIn" class="form-control">
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
    .V3 .new-card .card {
        margin-bottom: 0px;
    }

    .cards-edit-page .card-block {
        padding-right: 0;
    }

    .V3 body.V3 .multiselect__select {
        height: 35px;
        right: 0px;
        top: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .col-form-label {
        padding: 8px 0;
    }
    @media (max-width: 575.98px) {
        .V3 .col-block .col-form-label{
            padding: 8px 15px;
        }
    }
</style>
