<template>
    <div class="sub-tabs-component rental-tab">
        <tabs ref="claim-view-rental-tab-tabs" :options="{ defaultTabHash:'rental0' }" @changed="propsChangedRentalTab" @handleRightClick="handleRightClick">
            <!--    eslint-disable-->
            <tab :name="'Rental ' + (index+1)" :id="index" v-for="(rental, index) in rentals" :key="'rental'+rental.id" :propsActive="(index===0)">
                <claim-view-rental-tab-rental v-on="$listeners" :rental="rental" :propsHirerNames="computedHirerNames" :legal-firms="legalFirms">

                </claim-view-rental-tab-rental>
            </tab>
            <template slot="nav-item-tab-button" v-if="countRentals<10">
                <li style="margin-left:30px">
                    <div @click="clickAddRental" class="clickable">
                        <i class='bx bx-plus'></i><span>Add Another Rental</span>
                    </div>
                </li>
            </template>
        </tabs>

        <vue-context
            ref="rentals-tabs-context-menu"
            class="context-menu-right-click list-group"
            :closeOnClick="true"
            :closeOnScroll="true"
            :subMenuOffset="100"
            @open="contextOpen"
            @close="contextClose"
            style="width: 150px"
        >
          <template slot-scope="item">
            <b-button-group ref="ctx-button-group" vertical class="d-flex" v-if="item && item.data">
              <b-button ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block @click="removeRental(item)">Remove Rental</b-button>
            </b-button-group>
          </template>
        </vue-context>
    </div>
</template>
<script>
    /* eslint-disable */
    import ClaimViewRentalTabRental from "./rental/rental";
    import Axios from "axios";
    import {VueContext} from 'vue-context';

    export default {
        name: 'claim-view-rental-tab',
        props: {
            legalFirms: Array,
            rentals: Array,
            insured: Object,
            otherParties: Array,
            propsChangedRentalTab: Function,
            rentalsToRemove: {
              type: [Array, null],
              default: () => [],
            }
        },
        data: function () {
            return {
              contextOpenEvent: null,
            };
        },
        computed: {
            countRentals() {
                return this.rentals.length;
            },
            computedHirerNames() {
                let names = [];
                if (this.insured && this.insured.insured && this.insured.insured.vehicleOwner && this.insured.insured.vehicleOwner != '') {
                  names.push({name: this.insured.insured.vehicleOwner, type: 'insured', id: "0",});
                }
                if (this.insured && this.insured.insured && this.insured.insured.driverName && this.insured.insured.driverName != '') {
                  names.push({name: this.insured.insured.driverName, type: 'insured', id: "1000000",});
                }

                if (this.otherParties) {
                    _.forEach(this.otherParties, function (op) {
                      if (op.details && op.details.vehicleOwner && op.details.vehicleOwner != '') {
                        names.push({name: op.details.vehicleOwner, type: 'otherParty', id: op.id});
                      }
                      if (op.details && op.details.driverName && op.details.driverName != '') {
                        names.push({name: op.details.driverName, type: 'otherParty', id: (op.id * 1 + 1000000) + ""});
                      }
                    });
                }

                names.sort();

                return names;
            },

        },
        methods: {
          clickAddRental() {
              NProgress.start();
              let id = this.$route.params.claimId;
              Axios.post('/ir/claim/' + id + '/add-rental')
                  .then(response => {
                      if (response.status === 200 && response.data._status && response.data.rental) {
                          this.rentals.push(response.data.rental);

                      } else {
                          this.$toast.error(response.data._msg);
                      }
                  })
                  .catch(error => {
                      console.log(error);

                  })
                  .finally(() => {
                      NProgress.done();
                  });
          },
          contextOpen(event) {
            this.contextOpenEvent = event;
          },
          contextClose() {
            // let event = this.contextOpenEvent;
          },
          handleRightClick(e, index) {
            let item = this.rentals[index];
            console.log('ITEM', item)
            console.log('INDEX', index)
            console.log('E', e)
            this.$refs['rentals-tabs-context-menu'].open(e, item, index);
          },
          removeRental(item){

            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this', {
              title: 'Are you sure?',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              centered: true
            })
                .then(value => {
                  if (!value) return;
                  this.rentalsToRemove.push(item.data.id);
                  let i = this.rentals.indexOf(item.data);
                  if(i >= 0){
                    this.rentals.splice(i, 1);
                  }
                })
                .catch(err => {
                  console.error(err);
                });
          },
        },
        components: {
          ClaimViewRentalTabRental,
          VueContext,
        },

    };
</script>

<style lang="scss" scoped>
    .V3 .form-control:disabled {
        color: #000;
        background-color: #e9ecef!important;
    }

    .rental-tab ::v-deep .form-control:disabled,
    .rental-tab::v-deep .multiselect--disabled .multiselect__tags,
    .rental-tab ::v-deep .multiselect--disabled .multiselect__tags span {
        color: #000;
        background-color: #e9ecef!important;
    }

    .sub-tabs-component.rental-tab ::v-deep .tabs-component {
        position: inherit;
    }
</style>
