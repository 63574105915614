<template>
    <div class="mb-0-75 border pt-0-5 pb-0-5 dashboard-row-element clickable" @click="goToTask(task)">
        <div class="row">
            <div class="col-7 col-sm-8">
                <strong :class="{ 'text-muted': task.isViewed || isCompleted }">
                    {{ task.name }}
                    <template v-if="task.assessmentNumber"> - {{ task.assessmentNumber }}</template>
                    <template v-else-if="task.claimNumber"> - {{ task.claimNumber }}</template>
                    <template v-if="task.createdById == userId"> - <span style="color:blue">You</span></template>
                    <template v-else-if="task.createdById > 0"> - {{ task.createdBy }}</template>
                </strong>
            </div>
            <div class="col-5 col-sm-4 pl-0">
                <div class="d-flex align-items-center justify-content-end flex-wrap">
                    <span class="whitespace-nowrap">{{ task.createdAt | formatDateShort }}</span>
                    <span v-if="task.isViewed || isCompleted" class="pull-right d-flex align-items-center justify-content-end ml-2 flex-wrap">
                        <span v-if="task.priority == 'high'" class="task-priority">High</span>
                        <span v-if="task.priority == 'medium'" class="task-priority">Medium</span>
                        <span v-if="task.priority == 'low'" class="task-priority">Low</span>
                        <i class='bx bx-task font-20 text-muted ml-2'></i>
                    </span>
                    <span v-else class="pull-right d-flex align-items-center justify-content-end ml-2 flex-wrap">
                        <span v-if="task.priority == 'high'" class="task-priority text-danger font-nunito--bold">High</span>
                        <span v-if="task.priority == 'medium'" class="task-priority text-warning font-nunito--bold">Medium</span>
                        <span v-if="task.priority == 'low'" class="task-priority text-primary font-nunito--bold">Low</span>
                    <i class='bx bx-task font-20 theme-color ml-2'></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="row mt-0-5" :class="{ 'text-muted': task.isViewed || isCompleted }">
            <div class="col whitespace-nowrap overflow-hidden" style="text-overflow: ellipsis;">
                {{ task.notesText }}
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "task-summary",
        components: {},
        props: {
            task: {
                type: [Object, null],
                default() {
                    return {
                        "id": 0,
                        "name": "",
                        "dueDate": null,
                        "dueTime": null,
                        "notes": "",
                        "notesText": "",
                        "status": "none",
                        "isViewed": false,
                        "priority": "none",
                        "createdAt": "",
                        "createdBy": "",
                        "createdById": 0,
                        "assignedTo": 0,
                        "claimNumber": null,
                        "assessmentNumber": null,
                        "claimId": 0,
                        "assessmentId": 0,
                    };
                }
            },
        },
        computed: {
            ...mapGetters({
                userId: "currentUser/getCurrentUserId",
            }),
            isCompleted() {
                return this.task.status == 'completed';
            },
        },
        methods: {
            goToTask(task) {
                console.log('task', task);
                this.$router.push(
                    {name: 'AssessmentView', params: {action: 'goToTask', assessmentId: task.assessmentId, taskId: task.id}}
                );
            }
        }
    };
</script>

<style scoped>
.task-priority,
.bx {
    text-align: left;
}

.task-priority {
    width: 50px;
}

.bx {
    width: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1327px) {
    .task-priority, 
    .bx {
        text-align: right;
    }
}
</style>
