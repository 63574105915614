<script>
    export default {
        name: 'image-selector',
        props: {
            images: {
                type: Array,
                default() {
                    return [];
                }
            },
            path: {
                type: String,
                default() {
                    return '/';
                }
            },
            zeroImagesMessage: {
                type: String,
                defaultdefault() {
                    return 'No images available';
                }
            },
            propsSelectedImages: {
                type: Array,
                default() {
                    return [];
                },
            }
        },
        data: function () {
            return {
                selectedImages: []
            };
        },
        computed: {
            cols: function () {
                let col = 0;
                let colNum = 4;
                let columns = {0: [], 1: [], 2: [], 3: []};

                for (let index = 0; index < this.images.length; index++) {
                    if (col >= colNum) {
                        col = 0;
                    }
                    columns[col].push(index);
                    col++;
                }
                return columns;
            },
            allSelected() {
                return this.selectedImages.length === this.images.length && this.images.length > 0;
            }
        },
        watch: {
            selectedImages: function () {
                // console.log('image clicked', this.selectedImages)
                this.$emit('onChange', this.selectedImages);
            }
        },
        mounted() {
            if (this.propsSelectedImages) {
                this.selectedImages = this.propsSelectedImages;
            }
        },
        methods: {
            selectAllItems: function (data) {
                if (data.target.checked) {
                    this.selectedImages = [];
                    this.images.forEach((item) => {
                        this.selectedImages.push(item.id);
                    });
                } else {
                    this.selectedImages = [];
                }
            },
            isSelected(id) {
                return this.selectedImages.includes(id);
            },
            selectImage(id) {
                if (!this.isSelected(id)) {
                    this.selectedImages.push(id);
                } else {
                    const index = this.selectedImages.findIndex(_id => _id === id);
                    if (index === -1) return;
                    this.selectedImages.splice(index, 1);
                }
            },
            handleKeydown(e, id, index) {
                switch (e.keyCode) {
                    case 32: // space
                    case 13: // enter
                    {
                        e.preventDefault();
                        this.selectImage(id);
                        break;
                    }
                    case 37: // left
                    case 38: // up
                    {
                        e.preventDefault();
                        index = index === 0 ? this.images.length - 1 : index -= 1;
                        this.$refs['image-item'][index].focus();
                        break;
                    }
                    case 39:
                    case 40: {
                        e.preventDefault();
                        index = index === this.images.length ? 0 : index += 1;
                        this.$refs['image-item'][index].focus();
                        break;
                    }
                }
            }
        }
    };
</script>

<template>
    <div class="image-selector grid">
        <div class="image-selector-header">
            <label class="form-check-inline checkbox">
                <input class="form-check-input"
                       ref="image-selectall"
                       type="checkbox"
                       :checked="allSelected"
                       @change="selectAllItems">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Select All</span>
            </label>
        </div>
        <div class="image-selector__list">
            <div v-for="(image, index) in images"
                 class="image-selector__item"
                 :class="[{'selected': isSelected(image.id)}, `item-${image.id}`]"
                 :key="index"
                 ref="image-item"
                 tabindex="0"
                 @keydown="handleKeydown($event, image.id, index)">
                <div class="image-selector__overlay"></div>
                <label class="image-selector__checkbox form-check-inline checkbox" :for="index">
                    <input class="form-check-input"
                           tabindex="-1"
                           type="checkbox"
                           :id="index"
                           v-model="selectedImages"
                           :value="image.id"
                           data-id="image.id">
                    <span class="icon"><i class='bx bx-check'></i></span>
                </label>
                <img :src="path + image.src"
                     class="image-selector__img img-responsive w-100"
                     @click.stop="selectImage(image.id)">
            </div>
        </div>

        <!--
        <div class="image-selector-col col-sm-3">
            <div v-for="index in cols[0]" class="box box-block bg-white mb-1">
                <label class="form-check-inline checkbox" :for="index">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="selectedImages"
                           :id="index" :value="images[index].id"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span>
                </label>
                <img :src="path + images[index].src" class="wrap img-fluid">
            </div>
        </div>
        <div class="image-selector-col col-sm-3">
            <div v-for="index in cols[1]" class="box box-block bg-white mb-1">
                <label class="form-check-inline checkbox" style="margin-top:18px;" :for="index">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="selectedImages"
                           :id="index" :value="images[index].id"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span>
                </label>
                <img :src="path + images[index].src" class="wrap img-fluid">
            </div>
        </div>
        <div class="image-selector-col col-sm-3">
            <div v-for="index in cols[2]" class="box box-block bg-white mb-1">
                <label class="form-check-inline checkbox" style="margin-top:18px;" :for="index">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="selectedImages"
                           :id="index" :value="images[index].id"
                    >
                    <span class="icon"><i class='bx bx-fw bx-check'></i></span>
                </label>
                <img :src="path + images[index].src" class="wrap img-fluid">
            </div>
        </div>
        <div class="image-selector-col col-sm-3">
            <div v-for="index in cols[3]" class="box box-block bg-white mb-1">
                <label class="form-check-inline checkbox" style="margin-top:18px;" :for="index">
                    <input class="form-check-input"
                           type="checkbox"
                           v-model="selectedImages"
                           :id="index" :value="images[index].id"
                    >
                    <span class="icon"><i class='bx bx-check'></i></span>
                </label>
                <img :src="path + images[index].src" class="wrap img-fluid">
            </div>
        </div>
        -->
        <p v-if="images.length === 0" class="text-center">{{zeroImagesMessage}}</p>
    </div>
</template>

<style scoped>
    .V3 label.checkbox > span.text {
        width: 100px;
    }
</style>
<style>
    /*.image-selector input[type="checkbox"] {
      display: none;
    }*/

    .V3 .image-selector .image-selector-header {
        height: 40px;
        width: 100%;
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.07);
        margin-bottom: 15px;
    }

    .V3 .image-selector .image-selector-header label.checkbox {
        padding-top: 12px;
        margin-left: 15px;
    }

    .image-selector .image-selector-col {
        position: relative;
    }

    .image-selector .image-selector-col label {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        background: #FFFFFF;
        border-radius: 3px;
    }

    .V3 .image-selector .image-selector-col label.checkbox > span.icon {
        margin-right: 0px;
        border: none;
    }

    .image-selector .box-block {
        padding: 0.25rem;
    }

    /*
    .image-selector label:before {
        background-color: #fff;
        color: #fff;
        content: " ";
        display: block;
        border-radius: 50%;
        border: 1px solid #55aa55;
        position: absolute;
        top: -17px;
        left: -17px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 23px;
        transition-duration: .4s;
        transform: scale(0);
        font-weight: bold;
        font-size: 25px;
    }
    */
    .image-selector .image-selector-col label img {
        transition-duration: 0.2s;
        transform-origin: 50% 50%;
        background-color: #FFFFFF;
        width: 100%;
    }

    /*
    .image-selector :checked + label:before {
      content: "✓";
      background-color: #559955;
      transform: scale(1);
    }*/

    .image-selector .image-selector-col :checked + label img {
        /*  transform: scale(0.9); */
        /* box-shadow: 0 0 5px #333333;*/
        /*z-index: -1;*/
    }
</style>

<style scoped>
    .grid .image-selector__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        grid-gap: 15px;
        margin-bottom: 100px;
    }

    .grid .image-selector__item {
        position: relative;
        border-radius: 6px;
        height: 169px;
        overflow: hidden;
        border: 3px solid transparent;
    }

    .grid .image-selector__item:hover {
        cursor: pointer;
    }

    .grid .image-selector__item.selected {
        border: 3px solid #00B050;
    }

    .grid .image-selector__item .image-selector__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 5px;
        background-color: transparent;
        pointer-events: none;
    }

    .grid .image-selector__item:hover .image-selector__overlay,
    .grid .image-selector__item:focus .image-selector__overlay {
        background-color: #00B05036;
    }

    .grid .image-selector__img {
        border-radius: 3px;
        object-fit: cover;
        object-position: center;
    }

    .grid .selected .image-selector__img {
        border-radius: 3px;
    }

    .grid .image-selector__checkbox {
        position: absolute;
        top: 15.5px;
        right: 15.5px;
        margin: 0
    }

    .grid .image-selector__checkbox > span.icon {
        background-color: #F8F8F9;
        margin-right: 0;
    }

    .grid .image-selector__checkbox > span.icon i.bx {
        font-size: 24px;
        vertical-align: middle;
    }

    .grid .selected .image-selector__checkbox > span.icon {
        border: 2px solid #FFFFFF;
    }

    @media screen and (max-width: 640px) {
        .grid .image-selector__list {
            grid-template-columns: repeat(2, 1fr);
        }

        .grid .image-selector__item {
            height: 100px;
        }

        .grid .image-selector__checkbox {
            top: 10px;
            right: 10px;
        }
    }
</style>
